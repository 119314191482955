import React, { useEffect, useState } from "react";
import "styles/components/_admin-layout.scss";
import "styles/components/_topbar.scss";
import SkyMonitorLogo from "images/skymonitor-horizontal-name-logo.svg";
import { Link, Outlet } from "react-router-dom";
import PluginsContext, {
    defaultPluginsContext,
} from "components/plugin/plugins-context";
import useApi from "hooks/use-api";
import Controls from "components/administration/controls";

export interface AdministrationProps {}

const Administration = (props: AdministrationProps) => {
    const api = useApi();

    const loadPlugins = () =>
        api.plugins
            .getAll()
            .then((plugins) =>
                setPluginsContextData({
                    ...pluginsContextData,
                    plugins: plugins || [],
                })
            )
            .finally(() => setLoading(false));
    const [pluginsContextData, setPluginsContextData] = useState(
        defaultPluginsContext
    );
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadPlugins();
    }, []);

    if (loading) {
        return null;
    }

    return (
        <PluginsContext.Provider
            value={{ ...pluginsContextData, reload: loadPlugins }}
        >
            <div className="c-admin-layout">
                <div className="c-admin-layout__topbar">
                    <div className="c-topbar">
                        <Link to="">
                            <img
                                src={SkyMonitorLogo}
                                className="c-topbar__logo"
                                alt="SKY:Monitor"
                            />
                        </Link>
                        <Controls />
                    </div>
                </div>
                <Outlet />
            </div>
        </PluginsContext.Provider>
    );
};

export default Administration;
