import React, { useContext, useEffect, useState } from "react";
import BasicCard from "components/card/basic-card";
import { TextField } from "components/form";
import { JsonViewer } from "@textea/json-viewer";
import AppContext from "components/app/app-context";
import useDelayedEffect from "hooks/use-delayed-effect";
import DataQuery from "components/plugin/data-query";

const QueryTooltip = (
    <>
        Dotaz pro specifikaci dat pluginu.
        <DataQuery>Payload.Data = "value" AND Time &gt; "2002-01-01"</DataQuery>
    </>
);

const DashboardDataSearch = () => {
    const appContext = useContext(AppContext);
    const [query, setQuery] = useState("");
    const [data, setData] = useState<any>();

    useDelayedEffect(
        () => {
            if (query) {
                appContext.api.plugins
                    .searchData({ Query: query, Limit: 10 })
                    .then(setData);
            }
        },
        300,
        [query]
    );

    return (
        <BasicCard header="Rychlý náhled dat">
            <div style={{ maxHeight: "20rem", overflow: "auto" }}>
                <TextField
                    label="Dotaz"
                    type="query"
                    onChange={setQuery}
                    value={query}
                    placeholder='Např. Time > "2020-01-02" AND Payload.Field = "value"'
                    info={QueryTooltip}
                />

                <JsonViewer
                    value={data}
                    displayDataTypes={false}
                    rootName="data"
                />
            </div>
        </BasicCard>
    );
};

export default DashboardDataSearch;
