import React from "react";

export type DataQueryTokenProps = {
    className: string;
    children: React.ReactNode;
};

const DataQueryToken = ({ children, className }: DataQueryTokenProps) => {
    return <span className={`c-data-query__${className}`}>{children}</span>;
};

export default DataQueryToken;
