import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "components/dropdown";
import "styles/components/_info-tooltip.scss";

export type InfoTooltipProps = {
    children?: React.ReactNode;
};

const InfoTooltip = ({ children }: InfoTooltipProps) => {
    const [hidden, setHidden] = useState<boolean>(true);
    const [icon, setIcon] = useState<SVGSVGElement | null>(null);

    return (
        <>
            <FontAwesomeIcon
                className="c-info-tooltip"
                icon={faInfoCircle}
                ref={setIcon}
                onMouseEnter={() => setHidden(false)}
                onMouseLeave={() => setHidden(true)}
            />
            <Dropdown
                className="c-dropdown--info"
                hidden={hidden}
                referenceElement={icon}
            >
                {children}
            </Dropdown>
        </>
    );
};

export default InfoTooltip;
