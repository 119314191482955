import React from "react";
import * as date from "utils/date";
import { yesterday } from "utils/date";
import { Optional } from "utils/types";

export type PluginPageContextDef = {
    dateTimeFrom: Date;
    dateTimeTo?: Optional<Date>;
};

export const defaultPluginPageContext: PluginPageContextDef = {
    dateTimeFrom: yesterday(),
};

export default React.createContext(defaultPluginPageContext);
