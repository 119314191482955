import { DependencyList, useEffect, useState } from "react";

export default (
    callback: () => any,
    delay: number,
    dependencies: DependencyList
) => {
    const [isWaiting, setIsWaiting] = useState(false);

    useEffect(() => {
        setIsWaiting(true);
        const delayDebounceFn = setTimeout(() => {
            setIsWaiting(false);
            callback();
        }, delay);

        return () => clearTimeout(delayDebounceFn);
    }, dependencies);

    return isWaiting;
};
