import React from "react";
import { ColumnDef } from "components/table/table";
import { Link } from "react-router-dom";
import DataQuery from "components/plugin/data-query";

export type TableRowProps = {
    columns: ColumnDef[];
    data: any;
};

const TableRow = ({ data, columns }: TableRowProps) => {
    return (
        <tr className="c-table__row">
            {columns.map((column) => {
                let cellData = data[column.field];

                if (column.callback) {
                    cellData = column.callback(cellData, data);
                }

                if (
                    column.type === "datetime" &&
                    (typeof cellData === "string" ||
                        typeof cellData === "number")
                ) {
                    const date = new Date(cellData);

                    cellData = date.toLocaleString();
                }

                const link = column.link?.(data);

                if (column.type === "query" && typeof cellData === "string") {
                    cellData = <DataQuery>{cellData}</DataQuery>;
                }

                if (link) {
                    cellData = <Link to={link}>{cellData}</Link>;
                }

                return <td className="c-table__data-cell">{cellData}</td>;
            })}
        </tr>
    );
};

export default TableRow;
