import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Button } from "components/form";
import { usePopper } from "react-popper";
import { Optional } from "utils/types";

export type DropdownProps = {
    hidden?: boolean;
    children: React.ReactNode;
    referenceElement: Optional<Element>;
    className?: Optional<string>;
};

const Dropdown = ({
    children,
    hidden,
    referenceElement,
    className,
}: DropdownProps) => {
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
        null
    );
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: "bottom-end",
    });

    return ReactDOM.createPortal(
        hidden ? null : (
            <div
                className={`c-dropdown${className ? " " + className : ""}`}
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
            >
                {children}
            </div>
        ),
        document.body
    );
};

export default Dropdown;
