import React, {
    MouseEventHandler,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import "styles/components/_plugin-timeline.scss";
import ApacheLogo from "images/plugins/apache.png";
import CollabimLogo from "images/plugins/collabim.png";
import GoogleSearchConsoleLogo from "images/plugins/google-search-console.png";
import PageSpeedInsightsLogo from "images/plugins/page-speed-insights.png";
import UptimeRobotLogo from "images/plugins/uptime-robot.png";
import PluginTimelineRow from "components/dashboard/plugin-timeline/plugin-timeline-row";
import { PluginDataAggregateRequestDef } from "api/plugins";
import moment from "moment";
import PluginsContext from "components/plugin/plugins-context";

type MousePosition = {
    x: number;
};

const PluginTimeline = () => {
    const pluginsContext = useContext(PluginsContext);
    const yesterday = useMemo(
        () => new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
        []
    );
    const timeQuery = `Time > "${moment(yesterday).format()}"`;

    return (
        <div className="c-plugin-timeline">
            <div className="c-plugin-timeline__rows">
                {pluginsContext.plugins.map((plugin) => {
                    const chart = plugin.timelineCharts?.[0];

                    if (!chart) return null;

                    const specs: PluginDataAggregateRequestDef = {
                        query:
                            `PluginId="${plugin.id}" AND ${timeQuery}` +
                            (chart.query ? ` AND (${chart.query})` : ""),
                        interval: "1h",
                        filterBy: "Time",
                        aggregates: [chart.aggregate],
                    };

                    return (
                        <PluginTimelineRow
                            pluginId={plugin.id}
                            logo={plugin.logo as string}
                            specs={specs}
                            color={plugin.color}
                            dataLabel={chart.dataLabel}
                            dataUnit={chart.dataUnit}
                            name={chart.name}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default PluginTimeline;
