import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { className } from "utils/components";
import { usePopper } from "react-popper";
import ReactDOM from "react-dom";
import Tooltip from "components/tooltip/tooltip";

export type PluginsNavigationItemProps = {
    to: string;
    children?: React.ReactNode;
    disableActiveOutline?: boolean;
    className?: string;
    title?: string;
};

const PluginsNavigationItem = ({
    to,
    disableActiveOutline,
    children,
    title,
    className: additionalClassName,
}: PluginsNavigationItemProps) => {
    const [isHovered, setIsHovered] = useState(false);
    const [linkElement, setLinkElement] = useState<HTMLAnchorElement | null>();

    return (
        <>
            <NavLink
                ref={setLinkElement}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                className={({ isActive }) =>
                    className(
                        "c-plugins-navigation__plugin",
                        {
                            "c-plugins-navigation__plugin--active":
                                isActive && !disableActiveOutline,
                        },
                        additionalClassName
                    )
                }
                to={to}
                // title={title}
            >
                {children}
            </NavLink>
            <Tooltip
                reference={linkElement}
                visible={isHovered}
                placement="right"
                arrow
            >
                {title}
            </Tooltip>
        </>
    );
};

export default PluginsNavigationItem;
