import React, { useContext, useEffect, useState } from "react";
import "styles/components/_marketplace.scss";
import BasicCard from "components/card/basic-card";
import MarketplaceItem from "components/marketplace/marketplace-item";
import PluginsContext from "components/plugin/plugins-context";
import { route } from "api";
import axios from "axios";
import { range } from "utils/array";
import { Card } from "components/card";

export type MarketplacePluginDef = {
    id: string;
    name: string;
    paidApi: boolean;
    description: string;
    logo: string;
    content: string;
    author: string;
    verified?: boolean;
    url?: string;
    installer?: MarketplacePluginInstallerDef;
};

export type MarketplacePluginInstallerDef = {
    steps: MarketplacePluginInstallerStepDef[];
};

export type MarketplacePluginInstallerStepDef = {
    name: string;
    description: string;
    inputs?: MarketplacePluginInstallerInputDef[];
};

export type MarketplacePluginInstallerInputDef = {
    name: string;
    label: string;
    examples?: string[];
};

type MarketplacePluginsResponseDef = {
    plugins: MarketplacePluginDef[];
};

export const getMarketplacePlugins = async () => {
    return (
        await axios.get<MarketplacePluginsResponseDef>(
            "https://marketplace.skymonitor.eu/api/plugins"
        )
    ).data.plugins;
};

const Marketplace = () => {
    const pluginsContext = useContext(PluginsContext);
    const [plugins, setPlugins] = useState<MarketplacePluginDef[] | null>(null);

    useEffect(() => {
        getMarketplacePlugins().then(setPlugins);
    }, []);

    return (
        <div className="c-marketplace">
            <div className="c-marketplace__plugins">
                {pluginsContext.plugins && plugins
                    ? plugins.map((plugin) => {
                          return (
                              <MarketplaceItem
                                  key={plugin.id}
                                  plugin={plugin}
                                  icon={
                                      (plugin.logo ||
                                          route(
                                              "plugins/pagespeedinsights/logo.png"
                                          )) as string
                                  }
                                  installed={
                                      !!pluginsContext.plugins.some(
                                          (p) => p.id === plugin.id
                                      )
                                  }
                              />
                          );
                      })
                    : range(8, () => (
                          <Card skeleton style={{ height: "18rem" }} />
                      ))}
            </div>
        </div>
    );
};

export default Marketplace;
