import { useMemo, useState } from "react";

export default (
    key: string
): [string | undefined, (value: string | undefined) => void] => {
    const [sessionKey, setSessionKey] = useState<string | undefined>(
        localStorage.getItem(key) || undefined
    );

    return [
        sessionKey,
        (value) => {
            if (value === undefined) {
                localStorage.removeItem(key);
            } else {
                localStorage.setItem(key, value);
            }

            setSessionKey(value);
        },
    ];
};
