import { enqueueSnackbar } from "notistack";

export const handlePromise = (
    promise: Promise<unknown>,
    successMessage: string,
    errorMessage: string
) => {
    promise
        .then(() =>
            enqueueSnackbar(successMessage, {
                variant: "success",
            })
        )
        .catch(() =>
            enqueueSnackbar(errorMessage, {
                variant: "error",
            })
        );
};
