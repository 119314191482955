import React from "react";
import SideMenu from "components/administration/side-menu";
import { PluginsNavigation } from "components/administration/plugins-navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";

export type PageBodyProps = React.HTMLAttributes<HTMLDivElement> & {
    children?: React.ReactNode;
};

const PageBody = (props: PageBodyProps) => {
    const { children, className, ...baseProps } = props;

    return (
        <div className="c-admin-layout__main-content-container">
            <nav className="c-admin-layout__side-navigation">
                <PluginsNavigation />
                {/*<div>*/}
                {/*    <FontAwesomeIcon icon={faCircleQuestion} />*/}
                {/*</div>*/}
                <SideMenu />
            </nav>
            <main
                {...baseProps}
                className={
                    "c-admin-layout__body" + (className ? ` ${className}` : "")
                }
            >
                {children}
            </main>
        </div>
    );
};

export default PageBody;
