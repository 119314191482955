import React, { useContext, useEffect, useState } from "react";
import BasicCard from "components/card/basic-card";
import { Table } from "components/table";
import { ColumnDef } from "components/table/table";
import { PluginDataDef, PluginSearchDataResultDef } from "api/plugins";
import AppContext from "components/app/app-context";
import SearchTablePaginator from "components/table/search-table-paginator";

const data = [
    {
        plugin: "Google",
        date: "21. 2. 2002",
    },
    {
        plugin: "Google",
        date: "21. 2. 2002",
    },
    {
        plugin: "Google",
        date: "21. 2. 2002",
    },
    {
        plugin: "Google",
        date: "21. 2. 2002",
    },
    {
        plugin: "Google",
        date: "21. 2. 2002",
    },
    {
        plugin: "Google",
        date: "21. 2. 2002",
    },
];

const limit = 6;

const EventsCard = () => {
    const appContext = useContext(AppContext);
    const [page, setPage] = useState(1);
    const [data, setData] = useState<PluginSearchDataResultDef | null>(null);

    const columns: ColumnDef<PluginDataDef>[] = [
        {
            field: "PluginName",
            label: "Plugin",
        },
        {
            field: "Time",
            label: "Datum",
            type: "datetime",
        },
        {
            field: "TimeSynced",
            label: "Datum synchronizace",
            type: "datetime",
        },
    ];

    useEffect(() => {
        appContext.api.plugins
            .searchData({
                Desc: "true",
                Limit: limit,
                Sort: "Time",
                Query: "",
                Offset: (page - 1) * limit,
            })
            .then(setData);
    }, [page]);

    return (
        <BasicCard header="Poslední události">
            {data ? (
                <>
                    <Table columns={columns} data={data.items} />
                    <SearchTablePaginator
                        currentPage={page}
                        pagesCount={Math.ceil(data.totalCount / limit)}
                        onPageChange={setPage}
                    />
                </>
            ) : null}
        </BasicCard>
    );
};

export default EventsCard;
