import React, { useState } from "react";
import BasicCard, { BasicCardButtonDef } from "components/card/basic-card";
import { TextField } from "components/form";
import useApi from "hooks/use-api";

export type AddObserverCard = {
    onAdd?: () => void;
};

const AddObserverCard = ({ onAdd }: AddObserverCard) => {
    const api = useApi();

    const cardButtons: BasicCardButtonDef = {
        label: "Přidat",
        action: () =>
            api.notifications
                .addObserver({
                    name,
                    query,
                    timeBetweenNotifications: Number(timeBetweenNotifications),
                })
                .then(() => onAdd?.()),
    };
    const [name, setName] = useState("");
    const [query, setQuery] = useState("");
    const [timeBetweenNotifications, setTimeBetweenNotifications] =
        useState("");

    return (
        <BasicCard header="Přidat sledování" buttons={cardButtons}>
            <TextField label="Název" onChange={setName} value={name} />
            <TextField
                label="Sledovaný dotaz"
                description={`Např. 'PluginId = "apache2" AND Payload.StatusCode > 499'`}
                onChange={setQuery}
                value={query}
            />
            <TextField
                label="Časový rozestup"
                info="Časový rozestup kontrol dat v řýdu minut)"
                description="Např. 10"
                value={timeBetweenNotifications}
                // type="number"
                onChange={(v) => setTimeBetweenNotifications}
            />
        </BasicCard>
    );
};

export default AddObserverCard;
