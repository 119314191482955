import React, { useContext, useEffect, useId, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
    Area,
    AreaChart,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
} from "recharts";
import {
    PluginDataAggregateRequestDef,
    PluginDataGroupedAggregateRequestDef,
    PluginSearchDataRequestDef,
} from "api/plugins";
import AppContext from "components/app/app-context";
import * as date from "utils/date";
import moment from "moment";
import { fillAggregatedDateData } from "utils/plugin";

export type PluginTimelineRowProps = {
    pluginId: string;
    name: string;
    logo: string;
    specs: PluginDataGroupedAggregateRequestDef;
    color?: string;
    dataLabel?: string;
    dataUnit?: string;
};

const PluginTimelineRow = ({
    logo,
    pluginId,
    specs,
    color,
    dataLabel,
    dataUnit,
    name,
}: PluginTimelineRowProps) => {
    const appContext = useContext(AppContext);
    const [data, setData] = useState<any>([]);
    const yesterday = useMemo(() => date.yesterday(null, 0, 0, 0), []);
    const gradientId = useId();
    const chartColor = color || "#039af1";

    useEffect(() => {
        appContext.api.plugins
            .aggregateData(specs)
            .then((d) =>
                setData(
                    fillAggregatedDateData(
                        d[0],
                        "hour",
                        date.addHours(yesterday, 1),
                        new Date()
                    )
                )
            );
    }, [JSON.stringify(specs)]);

    return (
        <div className="c-plugin-timeline__row">
            <div className="c-plugin-timeline__details">
                <Link to={`/plugins/${pluginId}`}>
                    <img
                        alt=""
                        className="c-plugin-timeline__plugin-logo"
                        src={logo}
                    />
                </Link>
                <span className="c-plugin-timeline__chart-name">{name}</span>
            </div>

            <div className="c-plugin-timeline__events">
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart syncId="plugin-timeline" data={data}>
                        <Tooltip
                            isAnimationActive={false}
                            labelFormatter={(index, data) =>
                                data[0]
                                    ? moment(data[0]?.payload.field).format(
                                          "D.M. HH:mm"
                                      )
                                    : ""
                            }
                        />

                        <Area
                            dataKey="result"
                            name={dataLabel || "hodnota"}
                            type="monotone"
                            stroke={chartColor}
                            fill={`url(#${gradientId})`}
                        />

                        <defs>
                            <linearGradient
                                id={gradientId}
                                x1="0"
                                y1="0"
                                x2="0"
                                y2="1"
                            >
                                <stop
                                    offset="0%"
                                    stopColor={chartColor}
                                    stopOpacity={0.6}
                                />
                                <stop
                                    offset="95%"
                                    stopColor="#FFFFFF"
                                    stopOpacity={0.1}
                                />
                            </linearGradient>
                        </defs>
                    </AreaChart>
                </ResponsiveContainer>
            </div>

            {/*<div className="c-plugin-timeline__vertical-axis"></div>*/}
        </div>
    );
};

export default PluginTimelineRow;
