import React, { useContext, useEffect, useState } from "react";
import { JsonViewer } from "@textea/json-viewer";
import Page from "components/administration/page";
import AppContext from "components/app/app-context";
import { PluginDataDef } from "api/plugins";
import { useNavigate, useParams } from "react-router-dom";
import BasicCard, { BasicCardButtonDef } from "components/card/basic-card";
import PageNavigation from "components/administration/page-navigation";
import PageBody from "components/administration/page-body";
import { Button } from "components/form";
import usePlugin from "hooks/use-plugin";
import NotFound from "components/administration/not-found";
import PageContent from "components/administration/page-content";
import DataList from "components/data-list/data-list";
import DataListItem from "components/data-list/data-list-item";

export type PluginDataPageParamsDef = {
    pluginId: string;
    dataId: string;
};

const PluginDataPage = () => {
    const appContext = useContext(AppContext);
    const navigate = useNavigate();
    const { pluginId, dataId } = useParams<PluginDataPageParamsDef>();
    const plugin = usePlugin(pluginId as string);
    const [data, setData] = useState<PluginDataDef | null | false>(null);
    const pluginCardButton: BasicCardButtonDef = {
        label: "Navštívit",
        action: () => navigate(`/plugins/${pluginId}`),
    };

    useEffect(() => {
        if (dataId && pluginId) {
            appContext.api.plugins
                .getData(pluginId, dataId)
                .then((d) => setData(d || false))
                .catch(() => setData(false));
        }
    }, [dataId]);

    if (data === null) {
        return null;
    }

    if (!plugin || !data) {
        return <NotFound />;
    }

    return (
        <>
            <PageNavigation header={`Data ${dataId}`}>
                <Button
                    type="simple"
                    className="c-button--small"
                    onClick={() => navigate(-1)}
                >
                    Zpět
                </Button>
            </PageNavigation>
            <PageBody>
                <PageContent>
                    <BasicCard header="Plugin" buttons={pluginCardButton}>
                        <DataList>
                            <DataListItem label="Název">
                                {plugin.name}
                            </DataListItem>
                            <DataListItem label="Identifikátor">
                                {plugin.id}
                            </DataListItem>
                        </DataList>
                    </BasicCard>
                    <BasicCard header="Surová data">
                        {data ? (
                            <JsonViewer
                                value={data}
                                displayDataTypes={false}
                                rootName="data"
                            />
                        ) : null}
                    </BasicCard>
                </PageContent>
            </PageBody>
        </>
    );
};

export default PluginDataPage;
