import React from "react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataListItemInfo from "components/data-list/data-list-item-info";
import InfoTooltip from "components/form/info-tooltip";

export type DataListItemProps = {
    label: string;
    children: React.ReactNode;
    info?: string;
};

const DataListItem = ({ label, children, info }: DataListItemProps) => {
    return (
        <li className="c-data-list__item">
            <span className="c-data-list__label">
                {label}
                {info ? <InfoTooltip>{info}</InfoTooltip> : ""}
            </span>
            <div className="c-data-list__value">{children}</div>
        </li>
    );
};

export default DataListItem;
