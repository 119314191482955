import React from "react";
import { Card } from "components/card";
import CardBody from "components/card/card-body";
import "styles/components/_marketplace-item.scss";
import { Button } from "components/form";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircleCheck,
    faDollarSign,
    faHandHoldingDollar,
} from "@fortawesome/free-solid-svg-icons";
import Popup from "reactjs-popup";
import PluginInstaller from "components/plugin/plugin-installer";
import InstallPluginButton from "components/marketplace/install-plugin-button";
import { MarketplacePluginDef } from "components/marketplace/marketplace";

export type MarketplaceItemProps = {
    plugin: MarketplacePluginDef;
    icon: string;
    installed?: boolean;
};

const MarketplaceItem = ({ icon, installed, plugin }: MarketplaceItemProps) => {
    return (
        <Card>
            <CardBody>
                <div className="c-marketplace-item">
                    <Link
                        className="c-marketplace-item__header"
                        to={`/marketplace/plugins/${plugin.id}`}
                    >
                        <img className="c-marketplace-item__icon" src={icon} />
                        <div>
                            <h3 className="c-marketplace-item__name">
                                {plugin.name}
                            </h3>
                            <span className="c-marketplace-item__author">
                                {plugin.verified ? (
                                    <FontAwesomeIcon
                                        icon={faCircleCheck}
                                        className="c-marketplace-item__verified-icon"
                                    />
                                ) : null}
                                {plugin.author}
                            </span>
                            {plugin.paidApi ? (
                                <div>
                                    <span className="c-marketplace-item__tag">
                                        <FontAwesomeIcon icon={faDollarSign} />{" "}
                                        Placené API
                                    </span>
                                </div>
                            ) : null}
                        </div>
                    </Link>
                    <p className="c-marketplace-item__description">
                        {plugin.description}
                    </p>
                    <div className="c-marketplace-item__buttons">
                        <Link
                            to={`/marketplace/plugins/${plugin.id}`}
                            className="c-button c-button--small c-button--simple"
                        >
                            Informace
                        </Link>
                        <InstallPluginButton
                            plugin={plugin}
                            installed={installed}
                            className="c-button--small"
                        />
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};

export default MarketplaceItem;
