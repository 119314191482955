import React, { useState } from "react";
import { Modifier, usePopper } from "react-popper";
import { Optional } from "utils/types";
import ReactDOM from "react-dom";
import { Placement } from "@popperjs/core/lib/enums";
import "styles/components/_tooltip.scss";

export type TooltipProps = {
    reference: Optional<HTMLElement>;
    children?: React.ReactNode;
    visible?: boolean;
    placement?: Placement;
    arrow?: boolean;
};

const Tooltip = ({
    children,
    reference,
    visible,
    placement,
    arrow,
}: TooltipProps) => {
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>();
    const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>();

    const modifiers: Modifier<any>[] = [
        {
            name: "offset",
            options: {
                offset: [0, 20],
            },
        },
    ];

    if (arrow) {
        modifiers.push({ name: "arrow", options: { element: arrowElement } });
    }

    const { styles, attributes } = usePopper(reference, popperElement, {
        placement,
        modifiers,
    });

    return ReactDOM.createPortal(
        visible === false ? null : (
            <div
                ref={setPopperElement}
                className="c-tooltip"
                style={styles.popper}
                {...attributes.popper}
            >
                {children}

                {arrow ? (
                    <div
                        className="c-tooltip__arrow"
                        ref={setArrowElement}
                        style={styles.arrow}
                        {...attributes.arrow}
                    />
                ) : null}
            </div>
        ),
        document.body
    );
};

export default Tooltip;
