import React from "react";

type CardTitleProps = {
    children: string;
};

const CardTitle = ({ children }: CardTitleProps) => {
    return <span className="c-card__title">{children}</span>;
};

export default CardTitle;
