import React, { useState } from "react";
import Page from "components/administration/page";
import PageContent from "components/administration/page-content";
import BasicCard from "components/card/basic-card";
import { Table } from "components/table";
import ObserversCard from "components/notifications/observers-card";
import AddObserverCard from "components/notifications/add-observer-card";

const NotificationsPage = () => {
    const [key, setKey] = useState(Math.random());

    return (
        <Page name="Notifikace">
            <PageContent key={key}>
                <AddObserverCard onAdd={() => setKey(Math.random())} />
                <ObserversCard />
            </PageContent>
        </Page>
    );
};

export default NotificationsPage;
