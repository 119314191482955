import React, { FormEvent, useContext, useState } from "react";
import CoverPhoto from "images/skymonitor-vertical-name-logo.svg";
import "styles/components/_signup.scss";
import { Card } from "components/card";
import { Button, TextField } from "components/form";
import BasicCard from "components/card/basic-card";
import useTitle from "hooks/use-title";
import AppContext from "components/app/app-context";
import useApi from "hooks/use-api";
import useAuth from "hooks/use-auth";
import { redirect, useNavigate } from "react-router-dom";

const SignupPage = () => {
    useTitle("Registrace");

    const { login } = useAuth();
    const api = useApi();
    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");
    const [registerState, setRegisterState] = useState<"">("");

    const submitRegistration = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        api.users
            .register(username, password)
            .then(() => login(username, password).then(() => navigate("/")));
    };

    return (
        <div className="c-signup">
            <div className="c-signup__cover">
                <img
                    className="c-signup__cover-photo"
                    src={CoverPhoto}
                    alt=""
                />
            </div>
            <form className="c-signup__form" onSubmit={submitRegistration}>
                <BasicCard
                    className="c-card--spread"
                    bodyProps={{ className: "c-card__body--center" }}
                >
                    <h1>Registrace</h1>
                    <TextField
                        label="Uživatelské jméno"
                        value={username}
                        onChange={setUsername}
                    />
                    <TextField
                        label="Heslo"
                        type="password"
                        value={password}
                        onChange={setPassword}
                    />
                    <TextField
                        label="Heslo (znovu)"
                        type="password"
                        value={passwordAgain}
                        onChange={setPasswordAgain}
                    />
                    <Button>Registrovat</Button>
                </BasicCard>
            </form>
        </div>
    );
};

export default SignupPage;
