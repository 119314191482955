import React from "react";
import { className } from "utils/components";

export type CardProps = React.HTMLAttributes<HTMLDivElement> & {
    skeleton?: boolean;
    children?: React.ReactNode;
};

const Card = (props: CardProps) => {
    const {
        children,
        skeleton,
        className: additionalClassName,
        ...defaultProps
    } = props;

    return (
        <div
            {...defaultProps}
            className={className(
                "c-card",
                skeleton ? "c-card--skeleton" : null,
                additionalClassName
            )}
        >
            {children}
        </div>
    );
};

export default Card;
