import React, { useContext, useState } from "react";
import { Button, TextField } from "components/form";
import "styles/components/_plugin-installer.scss";
import PluginInstallerStep from "components/plugin/plugin-installer-step";
import { MarketplacePluginDef } from "components/marketplace/marketplace";
import MarketplacePluginDetail from "components/marketplace/martkeplace-plugin-detail";
import AppContext from "components/app/app-context";
import PluginsContext from "components/plugin/plugins-context";
import { useNavigate } from "react-router-dom";

export type PluginInstallerProps = {
    plugin: MarketplacePluginDef;
};

const PluginInstaller = ({ plugin }: PluginInstallerProps) => {
    const appContext = useContext(AppContext);
    const pluginsContext = useContext(PluginsContext);
    const navigate = useNavigate();
    const [installing, setInstalling] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const install = () => {
        setInstalling(true);

        appContext.api.plugins
            .installMarketplacePlugin(plugin.id)
            .catch(() => setError("error"))
            .finally(() => {
                setInstalling(false);

                pluginsContext
                    .reload()
                    .then(() => navigate(`/plugins/${plugin.id}`));
            });
    };

    return (
        <div className="c-plugin-installer">
            <div className="c-plugin-installer__content">
                <div className="c-plugin-installer__detail">
                    <MarketplacePluginDetail plugin={plugin} buttons="none" />
                </div>

                <div>
                    <h2>Instalace</h2>

                    <div className="c-plugin-installer__steps">
                        {plugin.installer?.steps.map((step, i) => (
                            <PluginInstallerStep
                                step={i + 1}
                                name={step.name}
                                inputs={step.inputs}
                                joint={plugin.installer?.steps.length !== i + 1}
                            >
                                {step.description}
                            </PluginInstallerStep>
                        ))}
                    </div>
                </div>
            </div>

            <div className="c-plugin-installer__buttons">
                <Button
                    isLoading={installing}
                    shake={!!error}
                    onClick={installing ? null : install}
                >
                    Instalovat bez nastavení
                </Button>
                <Button
                    isLoading={installing}
                    shake={!!error}
                    onClick={installing ? null : install}
                >
                    Instalovat
                </Button>
            </div>
        </div>
    );
};

export default PluginInstaller;
