import React from "react";
import "styles/components/_project-select.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const ProjectSelect = () => {
    return (
        <div className="c-project-select">
            <span className="c-project-select__value">eberry.cz</span>
            <FontAwesomeIcon
                className="c-project-select__chevron"
                icon={faChevronDown}
            />
        </div>
    );
};

export default ProjectSelect;
