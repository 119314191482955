import React, { useContext } from "react";
import "styles/components/_plugins-navigation.scss";
import PluginsContext from "components/plugin/plugins-context";
import { Link, NavLink } from "react-router-dom";
import { className } from "utils/components";
import PluginsNavigationItem from "components/administration/plugins-navigation/plugins-navigation-item";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const PluginsNavigation = () => {
    const pluginsContext = useContext(PluginsContext);

    return (
        <div className="c-plugins-navigation">
            {pluginsContext?.plugins?.map((plugin) => (
                <PluginsNavigationItem
                    to={`/plugins/${plugin.id}`}
                    title={`${plugin.name} plugin`}
                    key={plugin.id}
                >
                    {plugin.logo || plugin.icon ? (
                        <img
                            className="c-plugins-navigation__icon"
                            src={plugin.icon || plugin.logo}
                        />
                    ) : (
                        <span
                            className="c-plugins-navigation__no-icon"
                            style={{ color: plugin.color }}
                        >
                            {plugin.name[0]}
                        </span>
                    )}
                </PluginsNavigationItem>
            ))}

            <PluginsNavigationItem
                to={"/marketplace"}
                title="Přidat plugin"
                className="c-plugins-navigation__plugin--new"
                disableActiveOutline
            >
                <FontAwesomeIcon icon={faPlus} />
            </PluginsNavigationItem>
        </div>
    );
};

export default PluginsNavigation;
