import { PluginAggregatedDataDef } from "api/plugins";
import * as date from "utils/date";
import * as array from "utils/array";
import { TimeUnit } from "utils/date";

export type TokenTypeDef = (typeof queryTokens)[number]["token"];

export type TokenResultDef = {
    type: TokenTypeDef;
    value: string;
};

const restTokenPattern = /(?<rest>.*$)/;

export const queryTokens = [
    {
        pattern: /(?:AND|OR)/,
        token: "keyword",
    },
    {
        pattern: /(?:>=|<=|=|>|<|!=)/,
        token: "operator",
    },
    {
        pattern: /\s+/,
        token: "whitespace",
    },
    {
        pattern: /\d+(?:\.\d+)?/,
        token: "number",
    },
    {
        pattern: /[()]/,
        token: "bracket",
    },
    {
        pattern: /(?:"(?:[^"\\]|\\.)*"|'(?:[^'\\]|\\.)*')/,
        token: "string",
    },
    {
        pattern: /[a-zA-Z_][\w.]*/,
        token: "variable",
    },
] as const;

export const fillAggregatedDateData = (
    dateData: PluginAggregatedDataDef[],
    interval: date.TimeUnit,
    from: Date,
    to: Date
): PluginAggregatedDataDef[] => {
    if (dateData.length === 0) {
        return generateDateDataArray(from, to, interval);
    }

    const firstDate = new Date(dateData[0].field);
    const lastDate = new Date(dateData[dateData.length - 1].field);

    const res = [
        ...generateDateDataArray(from, firstDate, interval),
        ...dateData,
        ...generateDateDataArray(lastDate, to, interval),
    ];

    return res;
};

const generateDateDataArray = (from: Date, to: Date, interval: TimeUnit) =>
    array.range(
        Math.floor(Math.abs(date.getDiff(from, to, interval))),
        (addedValue) => ({
            field: date.add(from, addedValue, interval).toISOString(),
            result: 0,
        })
    );

export function* tokenizeQuery(query: string): Generator<TokenResultDef> {
    let rest = query;
    let prevLength: number;

    do {
        let result: TokenResultDef | undefined;
        prevLength = rest.length;

        for (let queryToken of queryTokens) {
            const regex = new RegExp(
                `^(?<token>${queryToken.pattern.source})${restTokenPattern.source}`
            );

            rest = rest.replace(regex, (_, token, rest) => {
                result = {
                    type: queryToken.token,
                    value: token,
                };

                return rest;
            });

            if (result) {
                yield result;

                break;
            }
        }

        if (!result && rest.length !== 0) throw new Error("Unknown token");
    } while (prevLength != rest.length);
}
