import React from "react";

export type CardFooterProps = {
    children?: React.ReactNode;
};

const CardFooter = ({ children }: CardFooterProps) => {
    return <div className="c-card__footer">{children}</div>;
};

export default CardFooter;
