import React from "react";
import "styles/components/_data-query.scss";
import { tokenizeQuery } from "utils/plugin";
import * as generator from "utils/generator";
import DataQueryToken from "components/plugin/data-query-token";

export type DataQueryProps = {
    children: string;
};

const tokenEle = (value: string, className: string) =>
    `<span class='c-data-query__${className}'>${value}</span>`;

const DataQuery = ({ children }: DataQueryProps) => {
    const tokenElements = generator.map(tokenizeQuery(children), (token, i) => {
        return (
            <DataQueryToken key={i} className={token.type}>
                {token.value}
            </DataQueryToken>
        );
    });

    return <code className="c-data-query">{tokenElements}</code>;
};

export default DataQuery;
