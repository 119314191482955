import React from "react";
import PluginsApi, { PluginsApiDef } from "api/plugins";
import UsersApi, { UsersApiDef } from "api/users";
import NotificationsApi, { NotificationsApiDef } from "api/notifications";
import { User } from "components/login/login";
import { voidCallback } from "utils/functions";

export type AppContextDef = {
    api: {
        plugins: PluginsApiDef;
        users: UsersApiDef;
        notifications: NotificationsApiDef;
    };
    user: User;
    isLoggedIn: boolean;
    login: (key: string) => void;
    logout: () => void;
};

export type LoginCallbackDef = (key: string) => void;

export const createAppContext = (
    user: User,
    login: LoginCallbackDef,
    logout: () => void,
    key: string | undefined = undefined
): AppContextDef => ({
    api: {
        plugins: PluginsApi(key),
        users: UsersApi(key),
        notifications: NotificationsApi(key),
    },
    user,
    login,
    logout: !!user?.username ? logout : voidCallback,
    isLoggedIn: !!user?.username,
});

export const defaultAppContext: AppContextDef = createAppContext(
    {
        key: "",
        username: "",
    },
    voidCallback,
    voidCallback
);

export default React.createContext(defaultAppContext);
