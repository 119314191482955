import React from "react";
import { className } from "utils/components";

const defaultMaxLength = 25;

const trimUrl = (url: string, maxLength: number): string => {
    if (url.length <= maxLength) {
        return url;
    }

    const half = Math.ceil(maxLength / 2);
    const endStart = url.length - half + 1;

    return url.substring(0, half) + "…" + url.substring(endStart);
};

export type UrlProps = React.HTMLProps<HTMLAnchorElement> & {
    children: string;
    max?: number;
    protocol?: boolean;
};

const Url = ({
    children,
    max,
    protocol,
    className: additionalClassName,
    ...defaultProps
}: UrlProps) => {
    let url = children;

    if (!protocol) {
        url = url.replace(/^https?:\/\//, "");
    }

    url = trimUrl(url, max || defaultMaxLength);

    return (
        <a
            className={className("c-url", additionalClassName)}
            {...defaultProps}
        >
            {url}
        </a>
    );
};

export default Url;
