import React from "react";
import BasicCard from "components/card/basic-card";
import "styles/components/_main-statistic.scss";
import { Spinner } from "components/spinner";

export type DashboardStatisticCard = {
    value: string;
    description: string;
    icon?: (props: any) => JSX.Element;
    isLoading?: boolean;
};

const DashboardStatisticCard = ({
    value,
    description,
    icon,
    isLoading,
}: DashboardStatisticCard) => {
    return (
        <BasicCard>
            <div className="c-main-statistic">
                {icon ? icon({ className: "c-main-statistic__icon" }) : null}
                <span className="c-main-statistic__value">
                    {isLoading ? (
                        <Spinner className="c-spinner--main-statistic" />
                    ) : (
                        value
                    )}
                </span>
                <span className="c-main-statistic__description">
                    {description}
                </span>
            </div>
        </BasicCard>
    );
};

export default DashboardStatisticCard;
