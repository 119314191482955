import React from "react";
import ProjectSelect from "components/administration/project-select";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faGear } from "@fortawesome/free-solid-svg-icons";
import ProfileIcon from "components/administration/profile-icon-props";
import useAuth from "hooks/use-auth";

const Controls = () => {
    const { logout } = useAuth();

    return (
        <div className="c-topbar__controls">
            <div className="c-topbar__item">
                <ProjectSelect />
            </div>
            <div className="c-topbar__item">
                <Link to="settings">
                    <FontAwesomeIcon
                        className="c-topbar__controls-icon"
                        icon={faGear}
                    />
                </Link>
            </div>
            <div className="c-topbar__item">
                <Link to="notifications">
                    <FontAwesomeIcon
                        className="c-topbar__controls-icon"
                        icon={faBell}
                    />
                </Link>
            </div>
            <div className="c-topbar__item">
                <ProfileIcon logout={logout} />
            </div>
        </div>
    );
};

export default Controls;
