import React from "react";
import { PluginDef } from "api/plugins";
import { asyncVoidCallback, voidCallback } from "utils/functions";

export type PluginsContextDef = {
    plugins: PluginDef[];
    reload: () => Promise<any>;
};

export const defaultPluginsContext: PluginsContextDef = {
    plugins: [],
    reload: asyncVoidCallback,
};

export default React.createContext(defaultPluginsContext);
