import React, { useContext, useEffect, useState } from "react";
import BasicCard, { BasicCardButtonDef } from "components/card/basic-card";
import { TextField } from "components/form";
import {
    PluginDef,
    PluginOptionDef,
    PluginOptionValueDef,
    PluginOptionValuesDef,
    PluginSettingsSchemaDef,
    PluginSettingsValuesDef,
} from "api/plugins";
import AppContext from "components/app/app-context";
import { enqueueSnackbar } from "notistack";
import { Simulate } from "react-dom/test-utils";
import error = Simulate.error;
import { handlePromise } from "utils/snackbar";

export type PluginOptionsCardProps = {
    pluginId: string;
};

type ValuesObject = Record<string, any>;

const mapOptionValuesToValuesObject = (optionValues: PluginOptionValuesDef) =>
    optionValues.values.reduce(
        (allValues, value) => ({
            ...allValues,
            [value.name]: value.value,
        }),
        {}
    );

const mapValuesObjectToOptionValues = (
    values: ValuesObject
): PluginOptionValuesDef => ({
    values: Object.keys(values).reduce(
        (allValues, valueName) => [
            ...allValues,
            {
                name: valueName,
                value: values[valueName],
            },
        ],
        [] as PluginOptionValueDef[]
    ),
});

const PluginOptionsCard = ({ pluginId }: PluginOptionsCardProps) => {
    const appContext = useContext(AppContext);
    const [settingsSchema, setSettingsSchema] =
        useState<PluginSettingsSchemaDef | null>(null);
    const [settingValues, setSettingValues] = useState<PluginSettingsValuesDef>(
        {}
    );

    useEffect(() => {
        appContext.api.plugins
            .getSettingsSchema(pluginId)
            .then(setSettingsSchema);
        appContext.api.plugins.getSettings(pluginId).then(setSettingValues);
    }, [pluginId]);

    const submitOptions = () => {
        if (pluginId) {
            handlePromise(
                appContext.api.plugins.setSettings(pluginId, settingValues),
                "Nastavení uloženo!",
                "Nastavení se napodařilo uložit"
            );
        }
    };

    const buttons: BasicCardButtonDef[] = [
        {
            action: submitOptions,
            label: "Uložit",
        },
    ];

    const settingsProperties = settingsSchema?.properties
        ? Object.keys(settingsSchema.properties)
        : null;

    return (
        <BasicCard
            header="Nastavení"
            buttons={buttons}
            bodyProps={{ className: "c-card__body--form" }}
            skeleton={!settingsProperties}
        >
            {settingsProperties?.map((propertyName) => {
                const property = settingsSchema?.properties[propertyName];

                return (
                    <TextField
                        key={propertyName}
                        label={property?.title || propertyName}
                        description={property?.description}
                        value={settingValues[propertyName] || ""}
                        onChange={(value) =>
                            setSettingValues({
                                ...settingValues,
                                [propertyName]: value,
                            })
                        }
                    />
                );
            })}
        </BasicCard>
    );
};

export default PluginOptionsCard;
