import { generate, range } from "utils/array";
import "styles/components/_paginator.scss";
import { useState } from "react";
import { className } from "utils/components";

export type SearchTablePaginatorProps = {
    currentPage: number;
    pagesCount: number;
    onPageChange?: (page: number) => void;
    surroundingPagesCount?: number;
};

const defaultSurroundingPagesCount = 2;

const isHollowPageNumber = (index: number, pageNumbers: number[]) => {
    if (index === 1) {
        return pageNumbers[index] - pageNumbers[0] > 1;
    } else if (index === pageNumbers.length - 2) {
        return pageNumbers[index + 1] - pageNumbers[index] > 1;
    }

    return false;
};

const generatePageNumbers = (
    currentPage: number,
    surPageCount: number,
    numberOfPages: number,
    startingPage: number = 1
): number[] => {
    const midPageOffset = surPageCount + 1;
    const midPageCount = midPageOffset * 2 + 1;

    if (midPageCount + startingPage >= numberOfPages) {
        return range(startingPage, numberOfPages);
    }

    const midStartPage =
        currentPage - midPageOffset > startingPage
            ? currentPage >= numberOfPages - midPageOffset
                ? numberOfPages - midPageCount
                : currentPage - midPageOffset
            : startingPage + 1;
    const midEndPage =
        currentPage + midPageOffset < numberOfPages
            ? currentPage <= startingPage + midPageOffset
                ? midPageCount + startingPage
                : currentPage + midPageOffset
            : numberOfPages - 1;

    return [startingPage, ...range(midStartPage, midEndPage), numberOfPages];
};

const SearchTablePaginator = ({
    currentPage,
    pagesCount,
    surroundingPagesCount,
    onPageChange,
}: SearchTablePaginatorProps) => {
    const pageNumbers = generatePageNumbers(
        currentPage,
        surroundingPagesCount || defaultSurroundingPagesCount,
        pagesCount
    );

    return (
        <div className="c-paginator">
            {pageNumbers.map((pageNumber, i) =>
                isHollowPageNumber(i, pageNumbers) ? (
                    <span
                        className="c-paginator__page c-paginator__page--empty"
                        key={pageNumber}
                    >
                        ...
                    </span>
                ) : (
                    <span
                        key={pageNumber}
                        className={className(
                            "c-paginator__page",
                            pageNumber === currentPage
                                ? "c-paginator__page--current"
                                : ""
                        )}
                        onClick={
                            onPageChange
                                ? () => onPageChange(pageNumber)
                                : undefined
                        }
                    >{`${pageNumber}`}</span>
                )
            )}
        </div>
    );
};

export default SearchTablePaginator;
