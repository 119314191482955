import React, { useEffect } from "react";
import "styles/components/_button.scss";
import { Spinner } from "components/spinner";
import { className } from "utils/components";
import { Optional } from "utils/types";

export type ButtonTypeDef = "primary" | "simple" | "danger" | "success";

export type ButtonProps = {
    children: React.ReactNode;
    onClick?: Optional<() => void>;
    isLoading?: boolean;
    shake?: boolean;
    type?: ButtonTypeDef;
    className?: string;
    disabled?: boolean;
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (props, ref) => {
        useEffect(() => {}, [props.shake]);

        const typeClass = `c-button--${props.type}`;

        return (
            <button
                ref={ref}
                className={className("c-button", props.className, {
                    [typeClass]: props.type,
                    "c-button--shake": props.shake,
                })}
                disabled={props.disabled}
                onClick={props.onClick || undefined}
            >
                {props.isLoading ? <Spinner inline /> : props.children}
            </button>
        );
    }
);

export default Button;
