import React, { useState } from "react";
import BasicCard, { BasicCardButtonDef } from "components/card/basic-card";
import { TextField } from "components/form";
import useApi from "hooks/use-api";
import { enqueueSnackbar } from "notistack";

const InviteUserCard = () => {
    const api = useApi();
    const [invitedEmail, setInviteEmail] = useState("");

    const submitButton: BasicCardButtonDef = {
        label: "Pozvat",
        action: () =>
            api.users
                .invite(invitedEmail)
                .then(() => {
                    setInviteEmail("");
                    enqueueSnackbar(
                        `Na e-mail ${invitedEmail} byla zaslána pozvánka`,
                        {
                            variant: "success",
                        }
                    );
                })
                .catch(() =>
                    enqueueSnackbar(`Nebylo možné pozvat uživatele`, {
                        variant: "error",
                    })
                ),
    };

    return (
        <BasicCard header="Pozvat uživatele" buttons={submitButton}>
            <TextField
                label="Email"
                value={invitedEmail}
                onChange={setInviteEmail}
                type="email"
                description="Email, na který bude zaslán odkaz s registrací do systému."
            />
        </BasicCard>
    );
};

export default InviteUserCard;
