import React from "react";
import Popup from "reactjs-popup";
import { Button } from "components/form";
import PluginInstaller from "components/plugin/plugin-installer";
import { MarketplacePluginDef } from "components/marketplace/marketplace";
import { Link } from "react-router-dom";
import { className } from "utils/components";

export type InstallPluginButtonProps = {
    plugin: MarketplacePluginDef;
    installed?: boolean;
    className?: string;
};

const InstallPluginButton = ({
    className: additionalClassName,
    plugin,
    installed,
}: InstallPluginButtonProps) => {
    return installed ? (
        <Link
            to={`/plugins/${plugin.id}`}
            className={className(
                "c-button c-button--success",
                additionalClassName
            )}
        >
            Zobrazit
        </Link>
    ) : plugin.installer ? (
        <Popup
            modal
            trigger={(open) => (
                <Button className={additionalClassName}>Instalovat</Button>
            )}
        >
            <PluginInstaller plugin={plugin} />
        </Popup>
    ) : (
        <Button className={additionalClassName} type="simple" disabled>
            Připravuje se
        </Button>
    );
};

export default InstallPluginButton;
