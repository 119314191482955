import React, { useContext, useState } from "react";
import BasicCard, { BasicCardButtonDef } from "components/card/basic-card";
import { TextField } from "components/form";
import { enqueueSnackbar } from "notistack";
import AppContext from "components/app/app-context";
import { handlePromise } from "utils/snackbar";
import useApi from "hooks/use-api";

const NewPasswordCard = () => {
    const api = useApi();
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [secondNewPassword, setSecondNewPassword] = useState("");

    const submitNewPasswordButton: BasicCardButtonDef = {
        label: "Uložit",
        action: () => {
            if (newPassword !== secondNewPassword) {
                enqueueSnackbar("Nové heslo se neshoduje", {
                    variant: "error",
                });
            } else {
                handlePromise(
                    api.users.changePassword(currentPassword, newPassword),
                    "Heslo bylo změněno!",
                    "Heslo se nepodařilo uložit (zkontrolujte aktuální heslo)"
                );
            }
        },
    };

    return (
        <BasicCard header="Nastavení hesla" buttons={submitNewPasswordButton}>
            <TextField
                label="Současné heslo"
                value={currentPassword}
                onChange={setCurrentPassword}
                type="password"
            />
            <TextField
                label="Nové heslo"
                value={newPassword}
                onChange={setNewPassword}
                type="password"
            />
            <TextField
                label="Nové heslo (znovu)"
                value={secondNewPassword}
                onChange={setSecondNewPassword}
                type="password"
            />
        </BasicCard>
    );
};

export default NewPasswordCard;
