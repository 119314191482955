import React, { useContext, useEffect, useState } from "react";
import BasicCard from "components/card/basic-card";
import {
    Area,
    AreaChart,
    Bar,
    BarChart,
    Cell,
    Legend,
    Line,
    LineChart,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import AppContext from "components/app/app-context";
import { Table } from "components/table";
import PluginPageContext from "components/plugin/plugin-page-context";
import { PluginChartDef, PluginDef } from "api/plugins";
import { Spinner } from "components/spinner";
import { Optional } from "utils/types";
import { fillAggregatedDateData } from "utils/plugin";
import moment, { now } from "moment";

export type PluginChartProps = {
    chart: PluginChartDef;
    plugin: PluginDef;
};
const colors = [
    "#009ef7",
    "#50cd89",
    "#ffc700",
    "#f1416c",
    "#7239ea",
    "#43ced7",
];

const renderChart = (
    chart: PluginChartDef,
    data: any[],
    from: Date,
    to?: Optional<Date>
) => {
    const dataKey = "result";
    const labelKey = "field";

    switch (chart.type) {
        case "pie":
            return (
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                        <Pie
                            data={data}
                            dataKey={dataKey}
                            fill="#8884d8"
                            label
                            nameKey={labelKey}
                        >
                            {data?.map?.((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={colors[index % 20]}
                                />
                            ))}
                        </Pie>
                        <Tooltip />
                        <Legend />
                    </PieChart>
                </ResponsiveContainer>
            );
        case "line":
            return (
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart data={data}>
                        <XAxis dataKey={labelKey} />
                        <YAxis />
                        <Tooltip />
                        <Area
                            type="monotone"
                            dataKey={dataKey}
                            stroke="#049af0"
                        />
                    </AreaChart>
                </ResponsiveContainer>
            );
        case "table":
            return (
                <Table
                    columns={[
                        {
                            field: "field",
                            label: "field",
                        },
                        {
                            field: "result",
                            label: "result",
                        },
                    ]}
                    data={data}
                />
            );
        case "text":
            return (
                <div className="c-main-statistic">
                    <span className="c-main-statistic__value c-main-statistic__value--center">
                        {Math.round((data as any).result * 100) / 100}
                    </span>
                    <span className="c-main-statistic__description">
                        {chart.dataLabel}
                    </span>
                </div>
            );
        case "line-timeline":
            return (
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                        data={fillAggregatedDateData(
                            data,
                            "hour",
                            from,
                            to || new Date()
                        )}
                    >
                        <XAxis
                            dataKey={labelKey}
                            minTickGap={15}
                            tickFormatter={(x) =>
                                moment(x).format("D. M. YY H:mm:ss")
                            }
                        />
                        <YAxis />
                        <Tooltip />
                        <Area
                            type="monotone"
                            dataKey={dataKey}
                            stroke="#049af0"
                            fill="#049af055"
                        />
                    </AreaChart>
                </ResponsiveContainer>
            );
        case "bar":
        default:
            return (
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={data}>
                        <XAxis dataKey={labelKey} />
                        <Tooltip />
                        <Bar dataKey={dataKey}>
                            {data?.map?.((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={colors[index % 20]}
                                />
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            );
    }
};

const PluginChart = ({ chart, plugin }: PluginChartProps) => {
    const appContext = useContext(AppContext);
    const pluginPageContext = useContext(PluginPageContext);
    const [data, setData] = useState<any>();

    useEffect(() => {
        setData(null);

        appContext.api.plugins
            .aggregateData({
                aggregates: [chart.aggregate as string],
                filterBy:
                    "groupBy" in chart ? (chart.groupBy as string) : undefined,
                query:
                    `PluginId = "${
                        plugin.id
                    }" AND Time > "${pluginPageContext.dateTimeFrom.toISOString()}"` +
                    (pluginPageContext.dateTimeTo
                        ? ` AND Time < "${pluginPageContext.dateTimeTo.toISOString()}"`
                        : "") +
                    (chart.query ? ` AND (${chart.query})` : ""),
                limit: chart.limit,
                interval: chart.interval ? "1h" : undefined,
            })
            .then((d) => setData(d[0]));
    }, [
        chart.name,
        pluginPageContext.dateTimeFrom.getTime(),
        pluginPageContext.dateTimeTo?.getTime(),
    ]);

    return (
        <BasicCard
            skeleton={!data}
            header={chart.name}
            style={{ gridArea: chart.position }}
        >
            {data
                ? renderChart(
                      chart,
                      data,
                      pluginPageContext.dateTimeFrom,
                      pluginPageContext.dateTimeTo
                  )
                : null}
        </BasicCard>
    );
};

export default PluginChart;
