import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { App } from "./components";

import "styles/generic/_normalize.scss";
import "styles/generic/_links.scss";
import "styles/elements/_html.scss";
import "styles/elements/_body.scss";
import "styles/vendor/_reactjs-popup.override.scss";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>
);
