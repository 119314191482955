import React from "react";
import Page from "components/administration/page";
import Marketplace from "components/marketplace/marketplace";
import PageContent from "components/administration/page-content";
import BasicCard from "components/card/basic-card";
import { Link } from "react-router-dom";

export type MarketplacePageProps = {};

const MarketplacePage = ({}: MarketplacePageProps) => {
    return (
        <Page name="Marketplace">
            <PageContent>
                <BasicCard>
                    Nemůžete najít svůj plugin?{" "}
                    <Link to="/settings">
                        Vlastní pluginy si můžete nahrát zde!
                    </Link>
                </BasicCard>
                <Marketplace />
            </PageContent>
        </Page>
    );
};

export default MarketplacePage;
