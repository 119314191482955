import { Optional } from "utils/types";

export type TimeUnit = "second" | "minute" | "hour" | "day";

export const getDiff = (date1: Date, date2: Date, unit: TimeUnit): number => {
    const t1 = date1.getTime();
    const t2 = date2.getTime();
    const diff = t1 - t2;

    switch (unit) {
        case "second":
            return diff / 1000;
        case "minute":
            return diff / 1000 / 60;
        case "hour":
            return diff / 1000 / 60 / 60;
        case "day":
            return diff / 1000 / 60 / 60 / 24;
    }
};

export const add = (date: Date, value: number, unit: TimeUnit) => {
    let valueToAdd: number;

    switch (unit) {
        case "second":
            valueToAdd = value * 1000;
            break;
        case "minute":
            valueToAdd = value * 1000 * 60;
            break;
        case "hour":
            valueToAdd = value * 1000 * 60 * 60;
            break;
        case "day":
            valueToAdd = value * 1000 * 60 * 60 * 24;
            break;
    }

    const out = new Date(date);
    out.setTime(out.getTime() + valueToAdd);

    return out;
};

export const yesterday = (
    hour?: Optional<number>,
    minute?: Optional<number>,
    second?: Optional<number>,
    ms?: Optional<number>
) => {
    const d = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);

    if (hour !== undefined && hour !== null) d.setHours(hour);
    if (minute !== undefined && minute !== null) d.setMinutes(minute);
    if (second !== undefined && second !== null) d.setSeconds(second);
    if (ms !== undefined && ms !== null) d.setMilliseconds(ms);

    return d;
};

export const addHours = (date: Date, hours: number) => {
    const newDate = new Date(date.getTime());
    newDate.setHours(date.getHours() + hours);

    return newDate;
};
