import React, { useEffect, useState } from "react";
import Page from "components/administration/page";
import { useParams } from "react-router-dom";
import useApi from "hooks/use-api";
import { ObserverDef } from "api/notifications";
import NotFound from "components/administration/not-found";
import PageContent from "components/administration/page-content";
import BasicCard, { BasicCardButtonDef } from "components/card/basic-card";
import AddObserverCard from "components/notifications/add-observer-card";
import { TextField } from "components/form";

const ObserverSettingsPage = () => {
    const api = useApi();
    const { id } = useParams();
    const [observer, setObserver] = useState<ObserverDef | null | false>(null);
    const [email, setEmail] = useState("");

    const cardButton: BasicCardButtonDef = {
        label: "Uložit",
        action: () => {
            if (observer) {
                api.notifications.updateEndpoints(observer.id, {
                    endpoints: [
                        {
                            emails: [
                                {
                                    to: email ? [email] : [],
                                },
                            ],
                        },
                    ],
                });
            }
        },
    };

    useEffect(() => {
        api.notifications.getAllObservers().then((observers) => {
            const foundObserver = observers.find((o) => o.id === id);

            setObserver(foundObserver || false);

            if (foundObserver) {
                setEmail(
                    foundObserver.notificationEndpoint?.[0].emailEndpoints
                        .to?.[0] || ""
                );
            }
        });
    }, []);

    if (observer === null) {
        return null;
    } else if (observer === false) {
        return <NotFound />;
    }

    return (
        <Page name={`Sledování: ${observer.name}`}>
            <PageContent>
                <BasicCard header="E-mail notifikace" buttons={cardButton}>
                    <TextField value={email} onChange={setEmail} />
                </BasicCard>
            </PageContent>
        </Page>
    );
};

export default ObserverSettingsPage;
