import React, { useContext, useEffect, useMemo, useState } from "react";
import Page from "components/administration/page";
import { Link, useParams } from "react-router-dom";
import NotFound from "components/administration/not-found";
import {
    getMarketplacePlugins,
    MarketplacePluginDef,
} from "components/marketplace/marketplace";
import { Card } from "components/card";
import "styles/components/_marketplace-detail.scss";
import ReactMarkdown from "react-markdown";
import CardBody from "components/card/card-body";
import PageContent from "components/administration/page-content";
import { Button } from "components/form";
import PluginsContext from "components/plugin/plugins-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { getDomain } from "utils/url";
import InstallPluginButton from "components/marketplace/install-plugin-button";
import MarketplacePluginDetail from "components/marketplace/martkeplace-plugin-detail";

const MarketplacePluginPage = () => {
    const { pluginId } = useParams();
    const pluginsContext = useContext(PluginsContext);
    const [plugin, setPlugin] = useState<null | false | MarketplacePluginDef>(
        null
    );

    useEffect(() => {
        getMarketplacePlugins().then((plugins) =>
            setPlugin(plugins.find((p) => p.id === pluginId) || false)
        );
    }, []);

    const isInstalled = pluginsContext.plugins.some((p) => p.id === pluginId);

    if (plugin === null) {
        return null;
    }

    return !plugin ? (
        <NotFound />
    ) : (
        <Page name={`Marketplace - ${plugin.name}`}>
            <PageContent>
                <Card>
                    <CardBody>
                        <MarketplacePluginDetail
                            plugin={plugin}
                            buttons={
                                isInstalled ? "installed" : "not-installed"
                            }
                        />
                    </CardBody>
                </Card>
                {plugin.content ? (
                    <Card>
                        <CardBody>
                            <ReactMarkdown>{plugin.content}</ReactMarkdown>
                        </CardBody>
                    </Card>
                ) : null}
            </PageContent>
        </Page>
    );
};

export default MarketplacePluginPage;
