import React, { useId, useState } from "react";
import "styles/components/_text-field.scss";
import InfoTooltip from "components/form/info-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import QueryInput from "components/form/query-input";
import { Optional } from "utils/types";

export interface TextFieldProps {
    label?: string;
    description?: string;
    onChange?: (value: string) => void;
    value?: string | number;
    info?: React.ReactNode;
    placeholder?: string;
    type?: "text" | "password" | "email" | "query" | "number";
}

const TextField = (props: TextFieldProps) => {
    const id = useId();
    const [showPassword, setShowPassword] = useState(false);

    const inputProps: React.HTMLAttributes<HTMLElement> = {
        className: "c-text-field__input",
        id: id,
        placeholder: props.placeholder,
    };

    return (
        <div className="c-text-field">
            {props?.label ? (
                <label className="c-text-field__label" htmlFor={id}>
                    {props.label}
                </label>
            ) : null}
            {props.info ? <InfoTooltip>{props.info}</InfoTooltip> : null}
            <div className="c-text-field__input-container">
                {props.type === "query" && false ? (
                    <QueryInput
                        {...inputProps}
                        value={props.value?.toString()}
                    />
                ) : (
                    <input
                        {...inputProps}
                        onInput={(e) => props.onChange?.(e.currentTarget.value)}
                        value={props.value || ""}
                        type={
                            props.type === "password" && showPassword
                                ? "text"
                                : props.type
                        }
                    />
                )}
                {props.type === "password" ? (
                    <FontAwesomeIcon
                        className="c-text-field__show-password-icon"
                        onClick={() => setShowPassword(!showPassword)}
                        icon={showPassword ? faEyeSlash : faEye}
                    />
                ) : null}
            </div>
            {props.description ? (
                <p className="c-text-field__description">{props.description}</p>
            ) : null}
        </div>
    );
};

export default TextField;
