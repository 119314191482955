import { Optional } from "utils/types";
import { useEffect } from "react";

const initialTitle = document.title;

export default (title: Optional<string>) => {
    useEffect(() => {
        document.title = (title ? `${title} | ` : "") + initialTitle;
    }, [title]);
};
