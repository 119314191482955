import React from "react";
import "styles/components/_table.scss";
import TableRow from "components/table/table-row";
import { Optional } from "utils/types";

export type ColumnDef<T = any> = {
    field: string | keyof T;
    label?: string;
    link?: (rowData: T) => string;
    type?: "text" | "datetime" | "query";
    callback?: (cellData: any, rowData: T) => any;
};

export type TableProps = {
    columns: ColumnDef[];
    data?: Optional<any[]>;
};

const Table = ({ columns, data }: TableProps) => {
    return (
        <table className="c-table">
            <thead>
                <tr>
                    {columns.map((column, i) => (
                        <th className="c-table__header" key={i}>
                            {column.label}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data?.map((rowData, i) => (
                    <TableRow data={rowData} columns={columns} key={i} />
                ))}
            </tbody>
        </table>
    );
};

export default Table;
