import React from "react";
import Page from "components/administration/page";
import BasicCard, { BasicCardButtonDef } from "components/card/basic-card";
import { Button, TextField } from "components/form";
import "styles/components/_settings.scss";
import FileUpload from "components/form/file-upload";
import PageContent from "components/administration/page-content";
import InviteUserCard from "components/settings/invite-user-card";

const SettingsPage = () => {
    const uploadPluginButtons: BasicCardButtonDef[] = [
        {
            action: () => console.log("Uploading..."),
            label: "Nainstalovat",
        },
    ];

    return (
        <Page name="Nastavení">
            <PageContent>
                <BasicCard
                    header="Instalovat plugin"
                    buttons={uploadPluginButtons}
                >
                    <p>
                        Pro instalici pluginu je zapotřebí mít ZIP soubor, který
                        obsahuje všechny potřebné soubory.
                    </p>

                    <FileUpload />
                </BasicCard>
                <InviteUserCard />
            </PageContent>
        </Page>
    );
};

export default SettingsPage;
