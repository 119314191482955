import React, { useContext, useState } from "react";
import { ReactComponent as LogoImage } from "images/skymonitor-vertical-name-logo.svg";
import "styles/components/_login.scss";
import BasicCard from "components/card/basic-card";
import { Button, TextField } from "components/form";
import CardBody from "components/card/card-body";
import { Card } from "components/card";
import AppContext from "components/app/app-context";
import app from "components/app/app";
import useTitle from "hooks/use-title";
import useAuth from "hooks/use-auth";

export interface User {
    username: string;
    key: string;
}

export interface LoginProps {}

interface UserCredentials {
    username: string;
    password: string;
}

const Login = (props: LoginProps) => {
    useTitle("Přihlášení");

    const { login } = useAuth();
    const [userCredentials, setUserCredentials] = useState<UserCredentials>({
        username: "",
        password: "",
    });
    const [loggingState, setLoggingState] = useState<
        "none" | "loading" | "error"
    >("none");

    const submitLogin = () => {
        setLoggingState("loading");

        login(userCredentials.username, userCredentials.password).catch(() =>
            setLoggingState("error")
        );
    };

    return (
        <form
            className="c-login"
            onSubmit={(e) => {
                e.preventDefault();
                submitLogin();
            }}
        >
            <Card>
                <CardBody className="c-card__body--login">
                    <LogoImage className="c-login__logo" />
                    <TextField
                        label="Přihlašovací jméno"
                        onChange={(username) =>
                            setUserCredentials({ ...userCredentials, username })
                        }
                        value={userCredentials.username}
                    />
                    <TextField
                        label="Heslo"
                        onChange={(password) =>
                            setUserCredentials({ ...userCredentials, password })
                        }
                        value={userCredentials.password}
                        type="password"
                    />
                    <Button
                        isLoading={loggingState === "loading"}
                        shake={loggingState === "error"}
                    >
                        Přihlásit se
                    </Button>
                </CardBody>
            </Card>
        </form>
    );
};

export default Login;
