import React, { useContext } from "react";
import Page from "components/administration/page";
import BasicCard, { BasicCardButtonDef } from "components/card/basic-card";
import { TextField } from "components/form";
import DataList from "components/data-list/data-list";
import DataListItem from "components/data-list/data-list-item";
import AppContext from "components/app/app-context";
import PageContent from "components/administration/page-content";
import NewPasswordCard from "components/profile/new-password-card";

const ProfilePage = () => {
    const appContext = useContext(AppContext);

    return (
        <Page name="Profil">
            <PageContent>
                <BasicCard header="Informace">
                    <DataList>
                        <DataListItem label="Uživatelské jméno">
                            {appContext.user.username}
                        </DataListItem>
                    </DataList>
                </BasicCard>
                <NewPasswordCard />
            </PageContent>
        </Page>
    );
};

export default ProfilePage;
