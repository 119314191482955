import { get, post } from "api";

export type CurrentUserDef = {
    id: string;
    username: string;
};

export type UsersApiDef = ReturnType<typeof api>;

const api = (key?: string) => ({
    getCurrentUser: (tempKey?: string) =>
        get<CurrentUserDef>({
            route: "user",
            key: tempKey || key,
        }),

    create: (username: string, password: string) =>
        post({
            route: "users",
            data: {
                username,
                password,
            },
        }),

    login: (username: string, password: string) =>
        post<string>({
            route: "users/login",
            data: {
                username,
                password,
            },
        }),

    changePassword: (oldPassword: string, newPassword: string) =>
        post({
            route: "user/password",
            key,
            data: {
                oldPassword,
                newPassword,
            },
        }),

    register: (username: string, password: string) =>
        post({
            route: "users",
            data: {
                username,
                password,
            },
        }),

    invite: (email: string) =>
        post({
            route: 'users/invite',
            key,
            data: {
                email
            }
        })
});

export default api;
