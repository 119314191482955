import { Optional } from "utils/types";

export const className = (
    ...classNames: Array<Optional<string | Record<string, any>>>
) =>
    classNames
        .map(mapClassName)
        .filter((c) => !!c)
        .join(" ");

const mapClassName = (c: Optional<string | Record<string, any>>) => {
    if (c && typeof c === "object") {
        return Object.keys(c)
            .filter((key) => c[key])
            .join(" ");
    }

    return c;
};
