import React from "react";
import { PluginDef } from "api/plugins";
import BasicCard from "components/card/basic-card";
import DataList from "components/data-list/data-list";
import DataListItem from "components/data-list/data-list-item";
import moment from "moment";
import cronstrue from "cronstrue/i18n";
import cronParser from "cron-parser";
import { firstToLowerCase } from "utils/string";
import DataQuery from "components/plugin/data-query";
import { Url } from "components/url";

export type PluginInfoCardProps = {
    plugin: PluginDef;
};

const PluginInfoCard = ({ plugin }: PluginInfoCardProps) => {
    const syncScheduleStr = plugin.periodicSyncSchedule
        ? firstToLowerCase(
              cronstrue.toString(plugin.periodicSyncSchedule, {
                  use24HourTimeFormat: true,
                  locale: "cs",
              })
          )
        : null;

    const nextSyncDate = plugin.periodicSyncSchedule
        ? cronParser.parseExpression(plugin.periodicSyncSchedule).next()
        : null;

    return (
        <BasicCard header="Informace o pluginu">
            <DataList>
                <DataListItem label="Jméno">{plugin.name}</DataListItem>
                <DataListItem label="Identifikátor">{plugin.id}</DataListItem>
                <DataListItem label="Odkaz na dashboard">
                    {plugin.dashboardUrl ? (
                        <Url href={plugin.dashboardUrl} target="_blank">
                            {plugin.dashboardUrl}
                        </Url>
                    ) : (
                        <i>žádný</i>
                    )}
                </DataListItem>
                <DataListItem
                    label="Chybový dotaz"
                    info="Dotaz, který je využíván pro získání chybových záznamů pluginu."
                >
                    {plugin.errorQuery ? (
                        <DataQuery>{plugin.errorQuery}</DataQuery>
                    ) : (
                        <i>žádný</i>
                    )}
                </DataListItem>
                <DataListItem label="Poslední synchronizace">
                    {plugin.lastSync ? (
                        moment(plugin.lastSync).format("D. M. YYYY H:mm:ss")
                    ) : (
                        <i>žádná</i>
                    )}
                </DataListItem>
                <DataListItem label="Další synchronizace">
                    {nextSyncDate
                        ? moment(nextSyncDate.toString()).format(
                              "D. M. YYYY H:mm:ss"
                          )
                        : ""}
                </DataListItem>
                <DataListItem
                    label="Rozvrh synchronizace"
                    info="Rozvrh spouštění pravidělné synchronizace pluginu zapsaný pomoc CRON notace."
                >
                    {syncScheduleStr || <i>žádný</i>}
                </DataListItem>
                <DataListItem label="Webhook synchronizace">
                    {plugin.webhookSynchronization ? "ano" : "ne"}
                </DataListItem>
            </DataList>
        </BasicCard>
    );
};

export default PluginInfoCard;
