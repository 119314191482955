import React, { useId } from "react";
import DatePicker, {
    registerLocale,
    CalendarContainer,
    ReactDatePickerProps,
} from "react-datepicker";
import "styles/components/_plugin-datetime-picker.scss";
import cs from "date-fns/locale/cs";
import moment, { now } from "moment";
import { Optional } from "utils/types";

registerLocale("cs", cs);

export type PluginDatetimePickerPropsDef = {
    label?: string;
    value?: Optional<Date>;
    onChange?: (date: Date | null) => void;
    isClearable?: boolean;
    selectsStart?: boolean;
    selectsEnd?: boolean;
    startDate?: Optional<Date>;
    endDate?: Optional<Date>;
    nowTime?: boolean;
};

const PluginDatetimePicker = ({
    onChange,
    value,
    label,
    isClearable,
    selectsStart,
    selectsEnd,
    startDate,
    endDate,
    nowTime,
}: PluginDatetimePickerPropsDef) => {
    const id = useId();

    return (
        <div className="c-plugin-datetime-picker">
            {label ? (
                <label className="c-plugin-datetime-picker__label" htmlFor={id}>
                    {label}
                </label>
            ) : null}
            <DatePicker
                id={id}
                className="c-plugin-datetime-picker__input"
                selected={value}
                onChange={(d) => onChange?.(d)}
                locale="cs"
                filterDate={(d) => d.getTime() <= now()}
                filterTime={(d) => d.getTime() < now()}
                dateFormat="eeee d. M. Y, H:mm"
                showTimeSelect
                shouldCloseOnSelect={false}
                isClearable={isClearable}
                selectsStart={selectsStart}
                selectsEnd={selectsEnd}
                startDate={startDate}
                endDate={endDate}
                injectTimes={nowTime ? [new Date()] : []}
            />
        </div>
    );
};

export default PluginDatetimePicker;
