import { get, post, put } from "api";

export type PluginDef = {
    id: string;
    name: string;
    logo?: string;
    icon?: string;
    color?: string;
    timelineCharts?: PluginTimelineChartDef[];
    charts?: PluginChartDef[];
    errorQuery?: string;
    dashboardUrl?: string;
    periodicSyncSchedule?: string;
    webhookSynchronization: boolean;
    lastSync?: string;
};

export type PluginChartDef = SingleAggregateChartDef | MultiAggregateChartDef;

export type SingleAggregateChartDef = BasePluginChart & {
    type: "text";
};
export type MultiAggregateChartDef = BasePluginChart & {
    type: "bar" | "line" | "pie" | "line-timeline" | "table";
    groupBy: string;
};
export type BasePluginChart = {
    name: string;
    aggregate?: string;
    query?: string;
    dataLabel?: string;
    limit?: number;
    orderBy?: string;
    descOrder?: boolean;
    position?: string;
    interval?: boolean;
};
export type PluginTimelineChartDef = {
    name: string;
    aggregate: string;
    query?: string;
    dataLabel?: string;
    dataUnit?: string;
};

export type PluginOptionDef = {
    name: string;
    dataType: unknown;
};

export type PluginOptionValuesDef = {
    values: PluginOptionValueDef[];
};

export type PluginOptionValueDef = {
    name: string;
    value: string;
};

export type PluginDataDef = {
    PluginId: string;
    PluginName: string;
    DataId: string;
    Time: string;
    Payload: any;
};

export type PluginSearchDataResultDef = {
    totalCount: number;
    items: PluginDataDef[];
};

export type PluginSearchDataRequestDef = {
    Query: string;
    Sort?: keyof PluginDataDef | string;
    Limit?: number;
    Offset?: number;
    Desc?: "true" | "false";
};

export type PluginDataAggregateRequestDef =
    | BasePluginDataAggregateRequestDef
    | PluginDataGroupedAggregateRequestDef;

export type BasePluginDataAggregateRequestDef = {
    aggregates: string[];
    query?: string;
    interval?: string;
    limit?: number;
};

export type PluginDataGroupedAggregateRequestDef =
    BasePluginDataAggregateRequestDef & {
        filterBy: string;
    };

export type PluginAggregatedDataDef = {
    field: string;
    result: number;
};

export type PluginSettingsSchemaDef = {
    properties: { [property: string]: PluginSettingsSchemaPropertyDef };
};

export type PluginSettingsSchemaPropertyDef = {
    title?: string;
    description?: string;
    type: "string" | "number" | "integer" | "boolean";
};

export type PluginSettingsValuesDef = Record<string, any>;

export type PluginsApiDef = ReturnType<typeof api>;

const api = (key?: string) => ({
    get: async (pluginId: string) =>
        (
            await get<PluginDef[]>({
                route: "plugins",
                key,
            })
        ).find((p) => p.id === pluginId) || null,

    getAll: async () =>
        get<PluginDef[]>({
            route: "plugins",
            key,
        }),

    getOptions: async (pluginId: string) =>
        get<PluginOptionDef[]>({
            route: `plugins/${pluginId}/options`,
            key,
        }),

    getOptionValues: async (pluginId: string) =>
        get<PluginOptionValuesDef>({
            route: `plugins/${pluginId}/options/values`,
            key,
        }),

    setOptionValues: async (pluginId: string, values: PluginOptionValuesDef) =>
        put({
            route: `plugins/${pluginId}/options/values`,
            key,
            data: values,
        }),

    setSettings: async (pluginId: string, settings: PluginSettingsValuesDef) =>
        post({
            route: `plugins/${pluginId}/settings`,
            key,
            data: settings,
        }),

    getSettings: async (pluginId: string) =>
        get<PluginSettingsValuesDef>({
            route: `plugins/${pluginId}/settings`,
            key,
        }),

    getSettingsSchema: async (pluginId: string) =>
        get<PluginSettingsSchemaDef>({
            route: `plugins/${pluginId}/settings/schema`,
            key,
        }),

    sync: async (pluginId: string, url?: string) =>
        post({
            route: `plugins/${pluginId}/run`,
            key,
        }),

    getData: async (pluginId: string, dataId: string) =>
        get<PluginDataDef>({
            route: `plugins/${pluginId}/data/${dataId}`,
            key,
        }),

    searchData: async (request: PluginSearchDataRequestDef) =>
        get<PluginSearchDataResultDef>({
            route: `plugins/data`,
            params: request,
            key,
        }),

    filterData: async (query: string) =>
        get({
            route: `plugins/filters`,
            params: { query },
            key,
        }),

    aggregateData: async <T extends PluginDataAggregateRequestDef>(
        request: T
    ): Promise<
        T extends PluginDataGroupedAggregateRequestDef
            ? PluginAggregatedDataDef[][]
            : PluginAggregatedDataDef[]
    > =>
        get({
            route: "plugins/data/aggregate",
            params: request,
            key,
        }),

    installMarketplacePlugin: async (pluginId: string) =>
        post({
            route: `plugins/marketplace/${pluginId}/install`,
            key,
        }),
});

export default api;
