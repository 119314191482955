import React, { useContext, useMemo } from "react";
import PluginsContext from "components/plugin/plugins-context";
import PluginChart from "components/plugin/plugin-chart";
import { PluginDef } from "api/plugins";

export type PluginDashboardChartsProps = {
    pluginId: string;
};

const PluginDashboardCharts = ({ pluginId }: PluginDashboardChartsProps) => {
    const pluginsContext = useContext(PluginsContext);
    const plugin = pluginsContext.plugins.find((p) => p.id === pluginId);

    return !plugin?.charts?.length ? null : (
        <div className="c-plugin-dashboard__charts">
            {plugin.charts?.map((chart, i) => (
                <PluginChart
                    key={`${pluginId}-${i}`}
                    plugin={plugin as PluginDef}
                    chart={chart}
                />
            ))}
        </div>
    );
};

export default PluginDashboardCharts;
