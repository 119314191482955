import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { usePopper } from "react-popper";
import "styles/components/_dropdown.scss";
import ReactDOM from "react-dom";
import { Button } from "components/form";

export type ProfileIconProps = {
    logout: () => void;
};

const ProfileIcon = ({ logout }: ProfileIconProps) => {
    const [iconElement, setIconElement] = useState<SVGSVGElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
        null
    );
    const [hidden, setHidden] = useState<boolean>(true);
    const { styles, attributes } = usePopper(iconElement, popperElement, {
        placement: "bottom-end",
    });

    return (
        <div
            onMouseEnter={() => setHidden(false)}
            onMouseLeave={() => setHidden(true)}
        >
            <Link to="profile">
                <FontAwesomeIcon
                    className="c-topbar__controls-icon"
                    ref={setIconElement}
                    icon={faUser}
                />
            </Link>

            {ReactDOM.createPortal(
                hidden ? null : (
                    <div
                        className="c-dropdown"
                        ref={setPopperElement}
                        style={styles.popper}
                        {...attributes.popper}
                    >
                        <Link className="c-dropdown__option" to="profile">
                            Profil
                        </Link>

                        <Button onClick={logout}>Odhlásit</Button>
                    </div>
                ),
                document.body
            )}
        </div>
    );
};

export default ProfileIcon;
