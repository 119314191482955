import React from "react";
import "styles/components/_card.scss";
import CardHeader from "components/card/card-header";
import CardBody from "components/card/card-body";
import Card from "components/card/card";
import CardFooter from "components/card/card-footer";
import { Button } from "components/form";
import { Spinner } from "components/spinner";
import CardTitle from "components/card/card-title";
import { ButtonTypeDef } from "components/form/button";

export type BasicCardButtonDef = {
    label: string;
    action: () => void;
    async?: boolean;
    type?: ButtonTypeDef;
};

export type BasicCardProps = React.HTMLAttributes<HTMLDivElement> & {
    header?: string;
    children?: React.ReactNode;
    buttons?: BasicCardButtonDef | BasicCardButtonDef[];
    toolbarButtons?: BasicCardButtonDef | BasicCardButtonDef[];
    bodyProps?: React.HTMLAttributes<HTMLDivElement>;
    loading?: boolean;
    skeleton?: boolean;
};

const inputButtonToArray = (
    buttons: BasicCardButtonDef | BasicCardButtonDef[] | undefined
): undefined | BasicCardButtonDef[] =>
    buttons && !Array.isArray(buttons) ? [buttons] : buttons;

const BasicCard = ({
    children,
    loading,
    buttons,
    toolbarButtons,
    header,
    skeleton,
    bodyProps: originalBodyProps,
    ...defaultProps
}: BasicCardProps) => {
    const bodyProps = loading
        ? {
              ...originalBodyProps,
              className: `${
                  originalBodyProps?.className || ""
              } c-card__body--loading`,
          }
        : originalBodyProps;

    const buttonsArr = inputButtonToArray(buttons);
    const toolbarButtonsArr = inputButtonToArray(toolbarButtons);
    const hasToolbarButtons = !!toolbarButtonsArr?.length;

    return (
        <Card {...defaultProps} skeleton={skeleton}>
            {header || hasToolbarButtons ? (
                <CardHeader>
                    {header ? <CardTitle>{header}</CardTitle> : null}

                    {hasToolbarButtons ? (
                        <div className="c-card__toolbar">
                            {toolbarButtonsArr.map((button, i) => (
                                <Button
                                    className="c-button--small"
                                    onClick={button.action}
                                    type={button.type}
                                    key={i}
                                >
                                    {button.label}
                                </Button>
                            ))}
                        </div>
                    ) : null}
                </CardHeader>
            ) : null}
            <CardBody {...bodyProps}>
                {loading ? <Spinner className="c-spinner--card" /> : children}
            </CardBody>
            {buttonsArr && buttonsArr.length > 0 ? (
                <CardFooter>
                    {buttonsArr.map((button, i) => (
                        <Button onClick={button.action} key={i}>
                            {button.label}
                        </Button>
                    ))}
                </CardFooter>
            ) : null}
        </Card>
    );
};

export default BasicCard;
