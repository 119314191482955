import { get, httpDelete, post, put } from "api/index";

export type ObserverDef = {
    id: string;
    query: string;
    name: string;
    timeBetweenNotifications: number;
    lastNotificationSend: string;
    notificationEndpoint: ObserverNotificationEndpointDef[] | null;
};

export type ObserverNotificationEndpointDef = {
    emailEndpoints: ObserverEmailEndpointDef;
};

export type ObserverEmailEndpointDef = {
    to: string[];
};

export type EndpointsDef = {
    endpoints: {
        emails: {
            to: string[];
        }[];
    }[];
};

export type NewObserverDef = {
    query: string;
    name: string;
    timeBetweenNotifications: number;
};

const alterObserverEndpointsToArray = (o: any): ObserverDef => {
    if (o.notificationEndpoint && typeof o.notificationEndpoint === "object") {
        return {
            ...o,
            notificationEndpoint: Object.values(o.notificationEndpoint),
        };
    }

    return o;
};

export type NotificationsApiDef = ReturnType<typeof api>;

const api = (key?: string) => ({
    getAllObservers: async () => {
        const result = await get<ObserverDef[]>({
            route: "observers",
            key,
        });

        return result.map(alterObserverEndpointsToArray);
    },

    addObserver: (data: NewObserverDef) =>
        post<string>({
            route: "observers",
            key,
            data,
        }),

    updateEndpoints: (observerId: string, data: EndpointsDef) =>
        post({
            route: `observers/${observerId}/endpoints`,
            key,
            data,
        }),

    deleteObserver: (id: string) =>
        httpDelete({
            route: `observers/${id}`,
            key,
        }),
});

export default api;
