import React from "react";
import { ReactComponent as SkyMonitorRainingIcon } from "images/skymonitor-raining.svg";
import "styles/components/_not-found.scss";
import { Link } from "react-router-dom";

export type NotFoundProps = {
    title?: string;
};

const NotFound = ({ title }: NotFoundProps) => {
    return (
        <div className="c-not-found">
            <SkyMonitorRainingIcon className="c-not-found__image" />
            <h2 className="c-not-found__title">
                {title || "Stránka nenalezena"}
            </h2>
            <Link to="/">Zpět na hlavní stránku</Link>
        </div>
    );
};

export default NotFound;
