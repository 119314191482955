import React from "react";

export type CardBodyProps = {
    children?: React.ReactNode;
    className?: string;
};

const CardBody = ({ children, className }: CardBodyProps) => {
    return <div className={`c-card__body ${className || ""}`}>{children}</div>;
};

export default CardBody;
