import React from "react";
import "styles/components/_file-upload.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";

const FileUpload = () => {
    return (
        <div className="c-file-upload">
            <FontAwesomeIcon
                icon={faCloudArrowUp}
                className="c-file-upload__icon"
            />
            <span className="c-file-upload__title">Soubor ZIP s pluginem</span>
        </div>
    );
};

export default FileUpload;
