export const map = <T, O>(
    generator: Generator<T>,
    callback: (item: T, index: number) => O
): O[] => {
    let item = generator.next();
    let i = 0;
    const out: O[] = [];

    while (!item.done) {
        out.push(callback(item.value, i++));

        item = generator.next();
    }

    return out;
};
