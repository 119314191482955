import React from "react";
import { ReactComponent as SpinnerIcon } from "images/icons/spinner.svg";
import "styles/components/_spinner.scss";
import { className } from "utils/components";

export type SpinnerProps = {
    className?: string;
    inline?: boolean;
};
const Spinner = ({ className: additionalClassName, inline }: SpinnerProps) => {
    return (
        <SpinnerIcon
            className={className("c-spinner", additionalClassName, {
                "c-spinner--inline": inline,
            })}
        />
    );
};

export default Spinner;
