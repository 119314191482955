import React, { useContext, useEffect, useState } from "react";
import "styles/components/_dashboard.scss";
import BasicCard from "components/card/basic-card";
import PluginTimelineCard from "components/dashboard/plugin-timeline/plugin-timeline-card";
import DashboardStatisticCard from "components/dashboard/dashboard-statistic-card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBell,
    faCalendarDay,
    faCalendarDays,
    faCircleExclamation,
    faPuzzlePiece,
} from "@fortawesome/free-solid-svg-icons";
import EventsCard from "components/dashboard/events-card";
import Page from "components/administration/page";
import AppContext from "components/app/app-context";
import { PluginDef } from "api/plugins";
import { ObserverDef } from "api/notifications";
import { Button, TextField } from "components/form";
import { JsonViewer } from "@textea/json-viewer";
import DashboardDataSearch from "components/dashboard/dashboard-data-search";
import { yesterday } from "utils/date";
import * as number from "utils/number";
import PluginsContext from "components/plugin/plugins-context";
import { Card } from "components/card";

const DashboardPage = () => {
    const [plugins, setPlugins] = useState<PluginDef[] | null>(null);
    const [observers, setObservers] = useState<ObserverDef[] | null>(null);
    const [lastDayEventCount, setLastDayEventCount] = useState<number>();
    const [totalDayEventCount, setTotalDayEventCount] = useState<number>();
    const [errorCount, setErrorCount] = useState<number>();
    const appContext = useContext(AppContext);
    const pluginsContext = useContext(PluginsContext);

    useEffect(() => {
        appContext.api.plugins.getAll().then(setPlugins);
        appContext.api.notifications.getAllObservers().then(setObservers);
        appContext.api.plugins
            .aggregateData({
                query: `Time > "${yesterday().toISOString()}"`,
                aggregates: ["COUNT(DataId)"],
            })
            .then((r) => setLastDayEventCount(r[0].result));
        appContext.api.plugins
            .aggregateData({
                aggregates: ["COUNT(DataId)"],
            })
            .then((r) => setTotalDayEventCount(r[0].result));
    }, []);

    useEffect(() => {
        const errorQuery = pluginsContext.plugins
            .filter((p) => !!p.errorQuery)
            .map((p) => `(PluginId="${p.id}" AND (${p.errorQuery}))`)
            .join(" OR ");
        if (errorQuery) {
            appContext.api.plugins
                .aggregateData({
                    query: `Date > "${yesterday().toISOString()}" AND (${errorQuery})`,
                    aggregates: ["COUNT(DataId)"],
                })
                .then((r) => setErrorCount(r[0].result));
        } else {
            setErrorCount(0);
        }
    }, [pluginsContext.plugins.length]);

    return (
        <Page name="Dashboard">
            <div className="c-dashboard">
                <div className="c-dashboard__main-statistics">
                    <DashboardStatisticCard
                        value={String(plugins?.length || 0)}
                        isLoading={!Array.isArray(plugins)}
                        description="Funkční pluginy"
                        icon={(props) => (
                            <FontAwesomeIcon {...props} icon={faPuzzlePiece} />
                        )}
                    />
                    <DashboardStatisticCard
                        value={
                            lastDayEventCount
                                ? number.format(lastDayEventCount)
                                : "0"
                        }
                        isLoading={!Number.isInteger(lastDayEventCount)}
                        description="Události (24 hod.)"
                        icon={(props) => (
                            <FontAwesomeIcon {...props} icon={faCalendarDay} />
                        )}
                    />
                    <DashboardStatisticCard
                        value={
                            totalDayEventCount
                                ? number.format(totalDayEventCount)
                                : "0"
                        }
                        isLoading={!Number.isInteger(totalDayEventCount)}
                        description="Události (celkem)"
                        icon={(props) => (
                            <FontAwesomeIcon {...props} icon={faCalendarDays} />
                        )}
                    />
                    <DashboardStatisticCard
                        value={errorCount ? number.format(errorCount) : "0"}
                        isLoading={!Number.isInteger(errorCount)}
                        description="Chyby (24 hod.)"
                        icon={(props) => (
                            <FontAwesomeIcon
                                {...props}
                                icon={faCircleExclamation}
                            />
                        )}
                    />
                    <DashboardStatisticCard
                        value={String(observers?.length || 0)}
                        isLoading={!Array.isArray(observers)}
                        description="Notifikátorů"
                        icon={(props) => (
                            <FontAwesomeIcon {...props} icon={faBell} />
                        )}
                    />
                </div>
                <div className="c-dashboard__events">
                    <EventsCard />
                    <DashboardDataSearch />
                </div>
                <PluginTimelineCard />
                <Card>{}</Card>
            </div>
        </Page>
    );
};

export default DashboardPage;
