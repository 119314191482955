import React, { useContext, useEffect, useMemo, useState } from "react";
import Page from "components/administration/page";
import { Link, useParams } from "react-router-dom";
import NotFound from "components/administration/not-found";
import {
    getMarketplacePlugins,
    MarketplacePluginDef,
} from "components/marketplace/marketplace";
import { Card } from "components/card";
import "styles/components/_marketplace-detail.scss";
import ReactMarkdown from "react-markdown";
import CardBody from "components/card/card-body";
import PageContent from "components/administration/page-content";
import { Button } from "components/form";
import PluginsContext from "components/plugin/plugins-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { getDomain } from "utils/url";
import InstallPluginButton from "components/marketplace/install-plugin-button";

export type MarketplacePluginDetailProps = {
    plugin: MarketplacePluginDef;
    buttons?: "none" | "installed" | "not-installed";
};

const MarketplacePluginDetail = ({
    plugin,
    buttons,
}: MarketplacePluginDetailProps) => {
    return (
        <div className="c-marketplace-detail">
            <img className="c-marketplace-detail__logo" src={plugin.logo} />
            <div className="c-marketplace-detail__info">
                <h2 className="c-marketplace-detail__name">{plugin.name}</h2>
                <span className="c-marketplace-detail__author">
                    {plugin.verified ? (
                        <FontAwesomeIcon
                            icon={faCircleCheck}
                            className="c-marketplace-detail__verified-icon"
                        />
                    ) : null}
                    {plugin.author}
                </span>
                {plugin.url ? (
                    <a
                        className="c-marketplace-detail__url"
                        target="_blank"
                        href={plugin.url}
                    >
                        {getDomain(plugin.url)}
                    </a>
                ) : null}
                {plugin.paidApi ? (
                    <div>
                        <span className="c-marketplace-detail__tag">
                            <FontAwesomeIcon icon={faDollarSign} /> Placené API
                        </span>
                    </div>
                ) : null}
                <p className="c-marketplace-detail__description">
                    {plugin.description}
                </p>
            </div>
            <div className="c-marketplace-detail__buttons">
                {buttons !== "none" ? (
                    <InstallPluginButton
                        plugin={plugin}
                        installed={buttons === "installed"}
                    />
                ) : null}
            </div>
        </div>
    );
};

export default MarketplacePluginDetail;
