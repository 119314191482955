export const format = (value: number): string => {
    if (value < 1_000) {
        return value.toString();
    } else if (value < 1_000_000) {
        return `${Math.floor(value / 1_000)} tis.`;
    } else if (value < 1_000_000_000) {
        return `${Math.floor(value / 1_000_000)} mil.`;
    } else {
        return value.toString();
    }
};
