import React, { useContext } from "react";
import { Outlet, Route } from "react-router-dom";
import { Login } from "components/login";
import AppContext from "components/app/app-context";

export type PrivateRouteProps = {
    children?: React.ReactNode;
};

export const PrivateRoute = ({ children }: PrivateRouteProps) => {
    const appContext = useContext(AppContext);

    return appContext.isLoggedIn ? <Outlet /> : <Login />;
};

export default PrivateRoute;
