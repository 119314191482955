import React from "react";
import PluginDatetimePicker from "components/plugin/plugin-datetime-picker";
import PageNavigation from "components/administration/page-navigation";
import PageBody from "components/administration/page-body";
import useTitle from "hooks/use-title";

export type PageProps = {
    children?: React.ReactNode;
    name?: string;
    isLoading?: boolean;
};

const Page = ({ children, name, isLoading }: PageProps) => {
    useTitle(name);

    return (
        <>
            <PageNavigation header={name} />
            <PageBody>{children}</PageBody>
        </>
    );
};

export default Page;
//
// const WidgetRight = (props: PageProps) => {
//     return (
//         <div className="widget__right">
//             <div className="widget__header"></div>
//             <div className="widget__body">{children}</div>
//             <div className="widget__footer"></div>
//         </div>
//     );
// };
