import React from "react";

export type PageContentProps = {
    children: React.ReactNode;
};

const PageContent = ({ children }: PageContentProps) => {
    return <div className="c-admin-layout__content">{children}</div>;
};

export default PageContent;
