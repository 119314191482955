export type NumberCallbackDef<T> = (value: number, index: number) => T;

export function range(end: number): number[];
export function range(start: number, end: number): number[];
export function range<T>(end: number, callback: NumberCallbackDef<T>): T[];
export function range<T>(
    start: number,
    end: number,
    callback: NumberCallbackDef<T>
): T[];
export function range<T>(
    param1: number,
    param2?: number | NumberCallbackDef<T>,
    param3?: NumberCallbackDef<T>
): any {
    const hasStart = typeof param2 === "number";

    const start: number = hasStart ? param1 : 0;
    const end: number = hasStart ? param2 : param1;
    const callback: NumberCallbackDef<T> | null =
        param3 || (typeof param2 === "function" ? param2 : null);

    const arr = [];

    if (callback) {
        let index = 0;

        for (let i = start; i <= end; i++) {
            arr.push(callback(i, index++));
        }
    } else {
        for (let i = start; i <= end; i++) {
            arr.push(i);
        }
    }

    return arr;
}

export const generate = <T = number>(
    end: number,
    mapFnc: (number: number) => T,
    start: number = 0
): T[] => {
    const arr: T[] = [];

    for (let i = start; i <= end; i++) {
        arr.push(mapFnc(i));
    }

    return arr;
};
