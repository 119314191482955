import React from "react";
import ReactMarkdown from "react-markdown";
import { MarketplacePluginInstallerInputDef } from "components/marketplace/marketplace";
import { TextField } from "components/form";

export type PluginInstallerStepProps = {
    step: string | number;
    name: string;
    inputs?: MarketplacePluginInstallerInputDef[];
    children?: React.ReactNode;
    joint?: boolean;
};

const PluginInstallerStep = ({
    step,
    name,
    children,
    inputs,
    joint,
}: PluginInstallerStepProps) => {
    return (
        <div className="c-plugin-installer__step">
            <div className="c-plugin-installer__step-number">
                <div className="c-plugin-installer__navigation-number">
                    {step}
                </div>
            </div>
            <h2 className="c-plugin-installer__step-name">{name}</h2>
            <div className="c-plugin-installer__step-joint">
                {joint !== false ? (
                    <div className="c-plugin-installer__joint"></div>
                ) : null}
            </div>
            <div className="c-plugin-installer__step-content">
                <div className="c-plugin-installer__description">
                    {typeof children === "string" ? (
                        <ReactMarkdown linkTarget="_blank">
                            {children}
                        </ReactMarkdown>
                    ) : (
                        children
                    )}
                </div>

                {inputs?.length ? (
                    <div className="c-plugin-installer__inputs">
                        {inputs.map((input) => (
                            <TextField
                                label={input.label}
                                description={
                                    input.examples?.length
                                        ? `Např. ${input.examples.join(", ")}`
                                        : undefined
                                }
                            />
                        ))}
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default PluginInstallerStep;
