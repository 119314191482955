import useApi from "hooks/use-api";
import { useContext } from "react";
import AppContext from "components/app/app-context";

export default () => {
    const appContext = useContext(AppContext);

    return {
        login: (username: string, password: string) =>
            appContext.api.users
                .login(username, password)
                .then((key) => appContext.login(key)),
        logout: appContext.logout,
    };
};
