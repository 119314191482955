import React, { useEffect, useMemo, useState } from "react";
import { Login } from "components/login";
import { User } from "components/login/login";
import { Administration } from "components/administration";
import AppContext, {
    AppContextDef,
    createAppContext,
    defaultAppContext,
} from "components/app/app-context";
import { closeSnackbar, SnackbarProvider } from "notistack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import "styles/generic/_notistack.scss";
import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "components/app/private-route";
import DashboardPage from "components/dashboard/dashboard-page";
import SignupPage from "components/signup/signup-page";
import ProfilePage from "components/profile/profile-page";
import SettingsPage from "components/settings/settings-page";
import useSessionValue from "hooks/use-session-value";
import PluginDashboardPage from "components/plugin/plugin-dashboard-page";
import PluginDataPage from "components/plugin/plugin-data-page";
import NotificationsPage from "components/notifications/notifications-page";
import MarketplacePluginPage from "components/marketplace/marketplace-plugin-page";
import MarketplacePage from "components/marketplace/marketplace-page";
import ObserverSettingsPage from "components/notifications/observer-settings-page";

const sessionKeyStorageKey = "session-key";

const App = () => {
    const [sessionKey, setSessionKey] = useSessionValue(sessionKeyStorageKey);
    const [user, setUser] = useState<User | false | undefined>(false);

    const appContext = useMemo<AppContextDef>(
        () =>
            createAppContext(
                user || { key: "", username: "" },
                setSessionKey,
                () => setSessionKey(undefined),
                sessionKey
            ),
        [user ? user.username : undefined, sessionKey]
    );

    useEffect(() => {
        if (sessionKey) {
            appContext.api.users
                .getCurrentUser(sessionKey)
                .then((u) =>
                    setUser({
                        key: sessionKey,
                        username: u.username,
                    })
                )
                .catch(() => setSessionKey(undefined));
        } else {
            setUser(undefined);
        }
    }, [sessionKey]);

    if (user === false) {
        return null;
    }

    return (
        <SnackbarProvider
            autoHideDuration={5000}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            maxSnack={5}
            action={(snackbarId) => (
                <FontAwesomeIcon
                    icon={faXmark}
                    onClick={() => closeSnackbar(snackbarId)}
                />
            )}
        >
            <AppContext.Provider value={appContext}>
                <Routes>
                    <Route path="signup" element={<SignupPage />} />

                    <Route element={<PrivateRoute />}>
                        <Route element={<Administration />}>
                            <Route path="/" element={<DashboardPage />} />
                            <Route path="/profile" element={<ProfilePage />} />
                            <Route
                                path="/settings"
                                element={<SettingsPage />}
                            />
                            <Route
                                path="/plugins/:pluginId"
                                element={<PluginDashboardPage />}
                            />
                            <Route
                                path="/plugins/:pluginId/data/:dataId"
                                element={<PluginDataPage />}
                            />

                            <Route
                                path="/notifications"
                                element={<NotificationsPage />}
                            />
                            <Route
                                path="/observers/:id"
                                element={<ObserverSettingsPage />}
                            />
                            <Route
                                path="/marketplace/plugins/:pluginId"
                                element={<MarketplacePluginPage />}
                            />
                            <Route
                                path="/marketplace"
                                element={<MarketplacePage />}
                            />
                        </Route>
                    </Route>
                </Routes>
            </AppContext.Provider>
        </SnackbarProvider>
    );
};

export default App;
