import React from "react";

export type PageNavigationProps = {
    children?: React.ReactNode;
    header?: React.ReactNode;
};

const PageNavigation = ({ children, header }: PageNavigationProps) => {
    return (
        <div className="c-admin-layout__navigation">
            {header ? (
                <h1 className="c-admin-layout__heading">{header}</h1>
            ) : null}
            {children}
        </div>
    );
};

export default PageNavigation;
