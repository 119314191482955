import React from "react";
import BasicCard, { BasicCardButtonDef } from "components/card/basic-card";
import PluginTimeline from "components/dashboard/plugin-timeline/plugin-timeline";
import button from "components/form/button";

const PluginTimelineCard = () => {
    const toolbarButtons: BasicCardButtonDef[] = [
        // {
        //     label: "Zobrazit",
        //     action: () => {},
        //     type: "simple",
        // },
    ];

    return (
        <BasicCard header="Časová osa" toolbarButtons={toolbarButtons}>
            <PluginTimeline />
        </BasicCard>
    );
};

export default PluginTimelineCard;
