import React, { useEffect, useState } from "react";
import BasicCard from "components/card/basic-card";
import { Table } from "components/table";
import { ColumnDef } from "components/table/table";
import useApi from "hooks/use-api";
import { ObserverDef } from "api/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";

const defaultColumns: ColumnDef[] = [
    {
        field: "name",
        label: "Název",
    },
    {
        field: "query",
        label: "Dotaz",
        type: "query",
    },
    {
        field: "timeBetweenNotifications",
        label: "Časový rezestup [min]",
    },
    {
        field: "lastNotificationSend",
        label: "Poslední notifikace",
        type: "datetime",
    },
];

const ObserversCard = () => {
    const api = useApi();
    const [observers, setObservers] = useState<ObserverDef[] | null>(null);

    const loadObservers = () => {
        if (observers) {
            setObservers(null);
        }

        api.notifications.getAllObservers().then(setObservers);
    };

    const columns: ColumnDef[] = [
        ...defaultColumns,
        {
            field: "_actions",
            callback: (cellData, rowData) => (
                <div className="c-table__controls">
                    <Link to={`/observers/${rowData.id}`}>
                        <FontAwesomeIcon
                            className="c-table__controls-icon"
                            icon={faPen}
                        />
                    </Link>

                    <FontAwesomeIcon
                        icon={faTrash}
                        className="c-table__controls-icon"
                        onClick={() =>
                            api.notifications
                                .deleteObserver(rowData.id)
                                .then(() =>
                                    setObservers(
                                        observers?.filter(
                                            (o) => o.id !== rowData.id
                                        ) || []
                                    )
                                )
                        }
                    />
                </div>
            ),
        },
    ];

    useEffect(() => {
        loadObservers();
    }, []);

    return (
        <BasicCard header="Sledování">
            <Table columns={columns} data={observers} />
        </BasicCard>
    );
};

export default ObserversCard;
