import React, { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import Page from "components/administration/page";
import AppContext from "components/app/app-context";
import PluginOptionsCard from "components/plugin/plugin-options-card";
import {
    PluginDataDef,
    PluginDef,
    PluginSearchDataResultDef,
} from "api/plugins";
import BasicCard from "components/card/basic-card";
import Timeline from "components/charts/timeline";
import { Table } from "components/table";
import { ColumnDef } from "components/table/table";
import "styles/components/_plugin-dashboard.scss";
import PluginDashboardCharts from "components/plugin/plugin-dashboard-charts";
import { Spinner } from "components/spinner";
import PluginPageContext, {
    PluginPageContextDef,
} from "components/plugin/plugin-page-context";
import { yesterday } from "utils/date";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import pluginsContext from "components/plugin/plugins-context";
import PluginsContext from "components/plugin/plugins-context";
import PageBody from "components/administration/page-body";
import PageNavigation from "components/administration/page-navigation";
import PluginDatetimePicker from "components/plugin/plugin-datetime-picker";
import { Button } from "components/form";
import PluginInfoCard from "components/plugin/plugin-info-card";
import NotFound from "components/administration/not-found";
import useTitle from "hooks/use-title";

type PluginDashboardRouteParameters = {
    pluginId: string;
};

const lastDataColumns: ColumnDef<PluginDataDef>[] = [
    {
        field: "Time",
        label: "Čas",
        type: "datetime",
    },
    {
        field: "TimeSynced",
        label: "Čas synchronizace",
        type: "datetime",
    },
    {
        field: "DataId",
        label: "ID",
        link: (data) => `/plugins/${data.PluginId}/data/${data.DataId}`,
    },
];

const PluginDashboardPage = () => {
    const appContext = useContext(AppContext);
    const pluginsContext = useContext(PluginsContext);
    const [lastDataResult, setLastDataResult] =
        useState<PluginSearchDataResultDef | null>(null);
    const [pluginPageContextValue, setPluginPageContextValue] =
        useState<PluginPageContextDef>({
            dateTimeFrom: yesterday(null, 0, 0, 0),
        });
    const { pluginId } = useParams<PluginDashboardRouteParameters>();
    const plugin = useMemo(
        () => pluginsContext.plugins.find((p) => p.id === pluginId),
        [pluginId, pluginsContext.plugins?.length]
    );

    useTitle(plugin ? `${plugin.name} plugin` : null);

    useEffect(() => {
        if (pluginId) {
            appContext.api.plugins
                .searchData({
                    Query: `PluginId = "${pluginId}"`,
                    Limit: 10,
                    Sort: "Time",
                    Desc: "true",
                })
                .then(setLastDataResult);
        }
    }, [pluginId]);

    return (
        <PluginPageContext.Provider value={pluginPageContextValue}>
            {plugin ? (
                <>
                    <PageNavigation header={plugin.name}>
                        <div style={{ display: "flex", gap: "2rem" }}>
                            <PluginDatetimePicker
                                label="Od"
                                value={pluginPageContextValue.dateTimeFrom}
                                onChange={(dateTimeFrom) =>
                                    dateTimeFrom &&
                                    setPluginPageContextValue({
                                        ...pluginPageContextValue,
                                        dateTimeFrom,
                                    })
                                }
                                selectsStart
                                startDate={pluginPageContextValue.dateTimeFrom}
                                endDate={
                                    pluginPageContextValue.dateTimeTo ||
                                    new Date()
                                }
                            />
                            <PluginDatetimePicker
                                label="Do"
                                value={
                                    pluginPageContextValue.dateTimeTo ||
                                    new Date()
                                }
                                onChange={(dateTimeTo) =>
                                    setPluginPageContextValue({
                                        ...pluginPageContextValue,
                                        dateTimeTo,
                                    })
                                }
                                selectsEnd
                                startDate={pluginPageContextValue.dateTimeFrom}
                                endDate={
                                    pluginPageContextValue.dateTimeTo ||
                                    new Date()
                                }
                                nowTime
                            />
                        </div>
                    </PageNavigation>
                    <PageBody>
                        <div className="c-plugin-dashboard">
                            <PluginDashboardCharts pluginId={plugin.id} />

                            <div className="c-plugin-dashboard__bottom-cards">
                                <BasicCard
                                    header="Poslední záznamy"
                                    skeleton={lastDataResult === null}
                                >
                                    {lastDataResult === null ? null : (
                                        <Table
                                            columns={lastDataColumns}
                                            data={lastDataResult?.items || []}
                                        />
                                    )}
                                </BasicCard>

                                <PluginOptionsCard pluginId={plugin.id} />

                                <PluginInfoCard plugin={plugin} />
                            </div>
                        </div>
                    </PageBody>
                </>
            ) : (
                <NotFound />
            )}
        </PluginPageContext.Provider>
    );
};

export default PluginDashboardPage;
